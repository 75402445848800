import React, { useState } from 'react';
import { Row, Col, Toast, ToastHeader, ToastBody } from 'reactstrap';
import ItemList from "../Item/ItemList";
import format from "date-fns/format";

const AnnBullList = props => {

  const [showArchived, setShowArchived] = useState(false);

  const stripHtml = myString => myString.replace(/<[^>]*>?/gm, '');

  const listRender = it => {
    let innerHtml = stripHtml(it.itemText).substring(0,200)+((it.itemText.length > 200) ? '...' : '');
    let dateOnStr = format((new Date(it.dateOn)), 'MMM d');
    let dateOffStr = format((new Date(it.dateOff)), 'MMM d');
    let annBullArr = [];
    if (it.onAnnouncement) {
      annBullArr.push('Ann')
    };
    if (it.onBulletin) {
      annBullArr.push('Bul');
    }
    let dateRange = it.alwaysOn ?
      '  (Always displayed)' :
      '   ('+dateOnStr+' - '+dateOffStr+' '+annBullArr.join(' ')+')';
    return (
      <Toast>
        <ToastHeader icon={it.urgent ? 'warning' : null}>
          {it.tease}{dateRange}
        </ToastHeader>
        <ToastBody className="item-list-body">
          <div dangerouslySetInnerHTML={{__html: innerHtml}}></div>
        </ToastBody>
      </Toast>
    )
  }

  const onArchivedClick = () => {
    setShowArchived(!showArchived);
  }

  return (
    <ItemList title={'Announcements / Bulletin'}
              list={props.list}
              enableShowArchived={true}
              showArchived={showArchived}
              onArchivedClick={onArchivedClick}
              itemKey={props.itemKey}
              onSelect={props.onSelect}
              fieldsToFilter={props.fieldsToFilter}
              render={listRender} />
  )
}

export default AnnBullList
