import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody } from 'reactstrap';
import AppContext from "../AppContext";
import 'handsontable/dist/handsontable.full.css';
import { useCollection } from "react-firebase-hooks/firestore";
import ItemList from "../Item/ItemList";
import StaffDirectoryControls from "./StaffDirectoryControls";
import newStaff from "../StaffDirectory/newStaff";
import getNewId from '../../utilities/getNewId';
import StaffDirectoryItem from "./StaffDirectoryItem";
import ReplaceMode from "./ReplaceMode";
import ImportWizardControls from "./ImportWizardControls";
import ImportWizard from "./ImportWizard";
import fileDownload from '../../utilities/fileDownload';

const R = require('ramda');

const StaffDirectory = props => {

  const appContext = useContext(AppContext);
  const fieldsToFilter = ['lastName','firstName','department','email','per0','per1','per2','per3','per4','per5','per6','per7'];

  const [dirty, setDirty] = useState(false);
  const [itemKey, setItemKey] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [item, setItem] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isWizardActive, setIsWizardActive] = useState(false);
  const [wizardStep, setWizardStep] = useState(0);
  const [noChangeAllowed, setNoChangeAllowed] = useState(false);
  const [replaceMode, setReplaceMode] = useState(false);

  let ref = appContext.db.collection(appContext.school).doc('staffDirectory');
  let [_list/*, loading, error*/] = useCollection(ref.collection('items'));
  let list = _list ? _list.docs.map(doc => {
    let data = doc.data();
    data.id = doc.id;
    return data;
  }).sort((a,b) => a.lastName+' '+a.firstName < b.lastName+' '+b.firstName ? -1 : 1) : [];

  useEffect(() => {
    if (list && itemKey) {
      let it = R.find(item => item.id === itemKey, list);
      setItem(it);
    }
  }, [itemKey])

  const onSelect = id => {
    if (!dirty) {
      setItemKey(id);
    } else {
      setNoChangeAllowed(true);
      setTimeout(() => {
        setNoChangeAllowed(false);
      }, 3000);
    }
  }

  const onChange = evt => {
    let field = evt.currentTarget.getAttribute('name');
    let value = evt.currentTarget.value;
    let _item = R.clone(item);
    _item[field] = value;
    setItem(_item);
    setDirty(true);
    setIsValid(checkIsValid(_item));
  }

  const checkIsValid = item => {
    return item.lastName.length > 0 && item.firstName.length > 0
  }

  const onAdd = () => {
    let item = newStaff();
    item.order = list.length;
    setItem(item);
    setDirty(true);
  }

  const onCancel = () => {
    let item = R.find(it => it.id === itemKey, list);
    if (item) {
      setItem(R.clone(item));
      setDirty(false);
      setIsValid(true);
    }
  }

  const onDelete = () => {
    setConfirmOpen(true);
  }

  const deleteItem = () => {
    appContext.db.collection(appContext.school)
      .doc('staffDirectory')
      .collection('items')
      .doc(item.id).delete()
      .then(() => {
        setDirty(false);
        setConfirmOpen(false);
        setItemKey(null);
        setItem(null);
      })
      .catch(err => {
        alert('Error deleting data');
        console.log(err);
      })
  }

  const onSave = () => {
    appContext.db.collection(appContext.school)
      .doc('staffDirectory')
      .collection('items')
      .doc(item.id).set(item)
      .then(() => {
        setDirty(false);
        setItemKey(item.id);
        setIsValid(true);
      })
      .catch(err => {
        alert("Error saving data");
      });
    appContext.db.collection(appContext.school)
      .doc('staffDirectory')
      .update({lastUpdated: (new Date()).getTime()})
  }

  const onFileChange = evt => {
    setSelectedFile(evt.target.files[0]);
  }

  const toggleConfirm = () => {
    setConfirmOpen(!setConfirmOpen);
  }

  const enableReplaceMode = () => {
    setReplaceMode(true);
  }

  const disableReplaceMode = () => {
    setReplaceMode(false);
  }

  const exportStaffDirectory = () => {
    let fieldText = ['Last name','First name','Email','Department','Extension','Room','Webpage','Per 1','Per 2','Per 3','Per 4','Per 5','Per 6','Per 7','Per 8','Per 9'];
    let fields = ['lastName','firstName','email','department','extension','room','webpage','per1','per2','per3','per4','per5','per6','per7','per8','per9'];
    let csv = list.map(item => {
      let _fields = R.clone(fields)
      _fields = _fields.map(field => `"${item[field]}"`);
      return _fields.join(',');
    });
    fileDownload(csv, fieldText.join(','), fields.join(','), 'staffDirectory');
    console.log(csv.join("\r\n"));
  }

  const upload = () => {
    let type = {
      contentType: selectedFile.type,
      customMetadata: {
        user: appContext.user.email,
        name: appContext.user.name
      }
    }
    let fnameArr = selectedFile.name.split('.');
    fnameArr[0] = fnameArr[0]+'_'+getNewId(5);
    let filename = fnameArr.join('.');
    let ref = appContext.storage.ref().child(appContext.school+'/staffDirectory/'+filename);
    ref.put(selectedFile, type)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {
        let _item = R.clone(item);
        _item.pictureUrl = url;
        setItem(_item);
        setDirty(true);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const toggleWizard = () => {
    setWizardStep(0);
    setIsWizardActive(!isWizardActive);
  }

  const listRender = it => (
    <h5>{it.lastName}, {it.firstName}</h5>
  )

  const getContent = () => {
    if (replaceMode) {
      return <ReplaceMode list={list} />
    } else if (isWizardActive) {
      return <ImportWizard wizardStep={wizardStep}
                           list={list}
      />
    } else {
      return <StaffDirectoryItem item={item}
                          onChange={onChange}
                          onFileChange={onFileChange}
                          upload={upload}
      />
    }
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <ItemList title={'Staff directory'}
                    list={list}
                    itemKey={itemKey}
                    onSelect={onSelect}
                    fieldsToFilter={fieldsToFilter}
                    render={listRender} />
        </Col>
        <Col md={8}>
          <Card>
            <CardHeader>
              {
                isWizardActive ?
                  <ImportWizardControls wizardStep={wizardStep}
                                        toggleWizard={toggleWizard}
                                        setWizardStep={setWizardStep}/> :
                  <StaffDirectoryControls onAdd={onAdd}
                                          onCancel={onCancel}
                                          onDelete={onDelete}
                                          onSave={onSave}
                                          toggleWizard={toggleWizard}
                                          enableReplaceMode={enableReplaceMode}
                                          disableReplaceMode={disableReplaceMode}
                                          replaceMode={replaceMode}
                                          dirty={dirty}
                                          isValid={isValid}
                                          item={item}
                                          exportStaffDirectory={exportStaffDirectory}
                  />
              }
            </CardHeader>
            <CardBody>
              { getContent() }
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={confirmOpen} toggle={toggleConfirm}>
        <ModalHeader toggle={toggleConfirm}>Confirm delete staff member</ModalHeader>
        <ModalBody><h5>Are you sure you want to delete this staff member?</h5></ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleConfirm}>No</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={noChangeAllowed}>
        <ModalBody>Please save or cancel your changes before selecting a new item</ModalBody>
      </Modal>
    </>
  )
}

export default StaffDirectory;
