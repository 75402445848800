import React, { useState, useContext, useEffect } from 'react';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import AppContext from "../AppContext";
import Papa from 'papaparse';
import newStaff from "./newStaff";
import Promise from 'bluebird';

const R = require('ramda')

const ImportWizard = props => {

  const appContext = useContext(AppContext);

  const [importFile, setImportFile] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [skipFirstLine, setSkipFirstLine] = useState(true);
  const [importSettings, setImportSettings] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const fieldNames = ['lastName','firstName','department','email','room','extension','per0','per1','per2','per3','per4','per5','per6','per7'];
  const fieldTexts = ['Last name','First name','Department','Email','Room','Extension','Period 0','Period 1','Period 2','Period 3','Period 4','Period 5','Period 6','Period 7','Period 8','Period 9'];

  useEffect(() => {
    if (importFile) {
      let reader = new FileReader();
      reader.onload = function () {
        let csvText = reader.result;
        let csvData = Papa.parse(csvText);
        if (skipFirstLine) {
          csvData = csvData.data.slice(1);
        }
        setCsvData(csvData);
        setImportSettings(R.range(0, csvData[0].length).map(() => ''));
      };
      reader.readAsText(importFile);
    }
  }, [importFile, skipFirstLine])

  useEffect(() => {
    if (props.wizardStep === 3) {
      setIsImporting(true);
      doImport();
    }
  }, [props.wizardStep])

  const doImport = () => {
    let lastNameIdx = importSettings.indexOf('lastName');
    let firstNameIdx = importSettings.indexOf('firstName');
    if (lastNameIdx === -1 || firstNameIdx === -1) {
      setErrorMessage('You must import the Last and First Names');
      return;
    }
    let sdObjs = csvData.map(row => {
      let lastName = row[lastNameIdx];
      let firstName = row[firstNameIdx];
      let newObj = R.find(it => it.lastName === lastName && it.firstName === firstName, props.list) || newStaff();
      newObj = row.reduce((newObj, value, idx) => {
        if (importSettings[idx].length > 0) {
          let fieldName = importSettings[idx];
          return R.assoc(fieldName, value, newObj);
        } else {
          return newObj;
        }
      }, newObj)
      return newObj;
    });
    let ref = appContext.db.collection(appContext.school)
      .doc('staffDirectory')
      .collection('items');
    sdObjs.map(obj => {
      return ref.doc(obj.id).set(obj);
    })
    Promise.all(sdObjs)
      .then(res => {
        setIsImporting(false);
        console.log(res);
      })
      .catch(err => {
        console.log('Import error');
        console.log(err);
      })
  }

  const onFileChange = evt => {
    setImportFile(evt.target.files[0]);
    setFileSize(evt.target.files[0].size);
  }

  const toggleSkipFirstLine = () => {
    setSkipFirstLine(!skipFirstLine);
  }

  const onChangeField = evt => {
    let idx = evt.currentTarget.getAttribute('data-idx')
    let value = evt.currentTarget.value;
    let _importSettings = R.clone(importSettings);
    _importSettings[idx] = value;
    setImportSettings(_importSettings);
  }

  const render = {
    0: () => (
        <Form>
          <FormGroup row>
            <Label for="importFile" sm={4}>Select import file</Label>
            <Col sm={8}>
              <Input type="file" name="file" id="exampleFile" onChange={onFileChange} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={4}>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" id="skip" checked={skipFirstLine} onChange={toggleSkipFirstLine}/>{'  '}
                  <Label>Skip first line</Label>
                </Label>
              </FormGroup>
            </Col>
            <Label sm={4}>File size: {fileSize === null ? '---' : fileSize}</Label>
          </FormGroup>
        </Form>
      ),

    1: () => (
      <>
        <h5>Number of records to import: {csvData.length}</h5>
        <Form>
          {
            csvData[0].map((field, idx) => (
              <FormGroup row key={'select'+idx}>
                <Col md={4}>
                  <Input type="select" value={importSettings[idx]} data-idx={idx} onChange={onChangeField}>
                    <option key="idxNoImport" value="">-- Do not import --</option>
                    {
                      R.range(0,fieldTexts.length).map(_idx => <option key={idx+'_'+_idx} value={fieldNames[_idx]}>{fieldTexts[_idx]}</option>)
                    }
                  </Input>
                </Col>
                {
                  R.range(0,2).map(_idx => {
                    if (csvData[_idx]) {
                      return (
                        <Col md={4} key={'col'+_idx+'row'+idx}>
                          <h5 className="import-field-data">{csvData[_idx][idx]}</h5>
                        </Col>
                      )
                    } else {
                      return null
                    }
                  })
                }
              </FormGroup>
            ))
          }
        </Form>
      </>
    ),

    2: () => (
      <>
        <h4>The imported information will be merged with existing information for staff members that are already in the directory. Staff members that do not already exist in the directory will be added.</h4>
        <h4>The import process is immediate and once you click &quot;Import&quot; you cannot go back, so be sure this is what you want to do.</h4>
      </>
    ),

    3: () => {
      if (errorMessage.length > 0) {
        return <h5>{errorMessage}</h5>
      } else if (isImporting) {
        return <h5>Importing information</h5>
      } else {
        return <h5>Import complete</h5>
      }
    }

  }

  return render[props.wizardStep]();

}

export default ImportWizard;

