import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import App from './App'
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
const firebaseConfig = require('./firebaseConfig');

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <App firebase={firebase} />,
  document.getElementById('root')
)
// registerServiceWorker();
