const firebaseConfig = {
  apiKey: "AIzaSyCZd5atC2o72hk4i5mgL3mjv8Uz9USZ_s8",
  authDomain: "phhs-website.firebaseapp.com",
  databaseURL: "https://phhs-website.firebaseio.com",
  projectId: "phhs-website",
  storageBucket: "phhs-website.appspot.com",
  messagingSenderId: "749785223064",
  appId: "1:749785223064:web:993d429f69c11213d8a5ed",
  measurementId: "G-0ZBP94W6CC"
};

module.exports = firebaseConfig;
