import React from 'react';
import { Col, Button, Form, FormGroup, Input, Label,
  InputGroup, Card, CardHeader, CardBody } from 'reactstrap';

const R = require('ramda');

const StaffDirectoryItem = props => {

  // const selectFile = () => {
  //   let el = document.getElementById("exampleFile");
  //   el.click();
  // }
  //
  return (
    props.item ?
      <div>
        <Card>
          <CardHeader>Staff information</CardHeader>
          <CardBody>
            <Form>
              <FormGroup row>
                <Label for="lastName" sm={4}>Last name</Label>
                <Col sm={8}>
                  <Input type="text" name="lastName" id="lastName" onChange={props.onChange} value={props.item.lastName} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="firstName" sm={4}>First name</Label>
                <Col sm={8}>
                  <Input type="text" name="firstName" id="firstName" onChange={props.onChange} value={props.item.firstName} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="department" sm={4}>Department</Label>
                <Col sm={8}>
                  <Input type="text" name="department" id="department" onChange={props.onChange} value={props.item.department} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="room" sm={4}>Room</Label>
                <Col sm={8}>
                  <Input type="text" name="room" id="room" onChange={props.onChange} value={props.item.room} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="extension" sm={4}>Extension</Label>
                <Col sm={8}>
                  <Input type="text" name="extension" id="extension" onChange={props.onChange} value={props.item.extension} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="email" sm={4}>Email</Label>
                <Col sm={8}>
                  <Input type="text" name="email" id="email" onChange={props.onChange} value={props.item.email} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="webpage" sm={4}>Webpage</Label>
                <Col sm={8}>
                  <Input type="text" name="webpage" id="webpage" onChange={props.onChange} value={props.item.webpage} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="pictureUrl" sm={4}>Picture URL</Label>
                <Col sm={8}>
                  <InputGroup>
                    <Input type="text" name="pictureUrl" id="pictureUrl" onChange={props.onChange} value={props.item.pictureUrl} />
                  </InputGroup>
                </Col>
              </FormGroup>
              {
                props.item.pictureUrl.length > 0 ?
                  <FormGroup row>
                    <Label for="pictureUrl" sm={4}>Current picture</Label>
                    <Col sm={8}>
                      <img className="staff-pix" src={props.item.pictureUrl} alt={'Picture of '+props.item.firstName+' '+props.item.lastName} />
                    </Col>
                  </FormGroup> : null
              }
              <FormGroup row>
                <Label for="pictureUrl" sm={4}>Select new picture</Label>
                <Col sm={6}>
                  <Input type="file" /*style={{display:'none'}}*/ name="file" id="exampleFile" onChange={props.onFileChange} />
                  {/*<Button onClick={selectFile} size="sm">Choose file</Button>*/}
                </Col>
                <Col sm={2}>
                  <Button size="sm" onClick={props.upload}>Upload</Button>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Schedule</CardHeader>
          <CardBody>
            {
              R.range(0, 10).map(idx => {
                return (
                  <FormGroup row key={'per'+idx}>
                    <Label for={'per'+idx} sm={4}>Period {idx}</Label>
                    <Col sm={8}>
                      <Input type="text" name={'per'+idx} id={'per'+idx} onChange={props.onChange} value={props.item['per'+idx]} />
                    </Col>
                  </FormGroup>
                )
              })
            }
          </CardBody>
        </Card>
      </div> : <h5>Select a staff member</h5>
  )
}

export default StaffDirectoryItem;
