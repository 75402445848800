import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Form, FormGroup, Input, Label,
  InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter,
  ListGroup, ListGroupItem } from 'reactstrap';
import Promise from 'bluebird';
import AppContext from "../AppContext";

const R = require('ramda');

// props.list is the staff directory list
const ReplaceMode = props => {

  const appContext = useContext(AppContext);

  const [classList, setClassList] = useState([]);
  const [selectedClassText, setSelectedClassText] = useState('');
  const [withThisClassText, setWithThisClassText] = useState('');
  const [teacherList, setTeacherList] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  useEffect(() => {
    setIsValid(selectedClassText.length > 0 && withThisClassText.length > 0)
  }, [selectedClassText, withThisClassText]);

  useEffect(() => {
    let _classList = props.list.reduce((acc, row) => {
      acc = R.range(0, 10).reduce((acc, period) => {
        let cl = row['per'+period];
        if (cl.length > 0 && !R.includes(cl, acc)) {
          acc.push(cl);
        }
        return acc
      }, acc);
      return acc;
    }, []);
    _classList.sort((a,b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1);
    setClassList(_classList);
  }, [props.list]);

  useEffect(() => {
    if (selectedClassText.length === 0) {
      setTeacherList([])
    } else {
      let _teacherList = props.list.reduce((acc, row) => {
        acc = R.range(0, 10).reduce((acc, period) => {
          if (row['per' + period] === selectedClassText) {
            acc.push(row);
          }
          return acc;
        }, acc)
        return acc;
      }, [])
      _teacherList = R.uniqBy(t => t.lastName + ' ' + t.firstName, _teacherList);
      setTeacherList(_teacherList);
    }
  }, [props.list, selectedClassText]);

  const toggleConfirmModal = () => {
    setConfirmModal(!confirmModal);
  }

  const replace = () => {
    let newTeacherList = teacherList.map(t => {
      R.range(0, 10).forEach(p => {
        if (t['per'+p] === selectedClassText) {
          t['per'+p] = withThisClassText
        }
      })
      return t;
    });
    let promises = newTeacherList.map(t => {
      return appContext.db.collection(appContext.school)
        .doc('staffDirectory')
        .collection('items')
        .doc(t.id)
        .update(t)
    });
    Promise.all(promises)
      .then(results => {
        setConfirmModal(false);
      })
      .catch(err => {
        console.log('Replace mode update error');
        console.log(err);
      })
  }

  return (
    <Row>
      <Col>
        <Form>
          <FormGroup>
            <Label for="classItem">Replace this class text</Label>
            <Input type="select" name="classItem" value={selectedClassText} onChange={evt => setSelectedClassText(evt.currentTarget.value)} >
              <option key="@@select" value="">-- Select a class --</option>
              {
                classList.map(cl => <option key={cl} value={cl}>{cl}</option>)
              }
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="withThisClass">with this new text</Label>
            <InputGroup>
              <Input type="text" name="withThisClass" value={withThisClassText} onChange={evt => setWithThisClassText(evt.currentTarget.value)} />
              <InputGroupAddon addonType="append">
                <Button disabled={!isValid} onClick={toggleConfirmModal}>Perform replacement</Button>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        </Form>
        <h5>Teachers with this course to be changed</h5>
        <ListGroup>
          {
            teacherList.map(t => <ListGroupItem key={t.lastName+' '+t.firstName}><h5>{t.lastName+', '+t.firstName}</h5></ListGroupItem>)
          }
        </ListGroup>
        <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
          <ModalHeader toggle={toggleConfirmModal}>Confirm text replacement</ModalHeader>
          <ModalBody>Confirm this replacement. You can't go back!</ModalBody>
          <ModalFooter>
            <Button onClick={replace}>Replace</Button>
            <Button onClick={toggleConfirmModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Col>
    </Row>
  )
}

export default ReplaceMode;
