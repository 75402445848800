import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardHeader, CardBody, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input,
  FormFeedback, FormText} from 'reactstrap';
import AppContext from "../AppContext";
import getNewId from '../../utilities/getNewId';
const SCHOOL = require("../../constants").SCHOOL;

const UsersItem = props => {

  const appContext = useContext(AppContext);

  const [dirty, setDirty] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [email, setEmail] = useState(null);
  const [id, setId] = useState(null);
  const [item, setItem] = useState(null);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    if (props.item) {
      setId(props.item.id);
      setItem(props.item);
      setEmail(props.item.email);
      setIsSuperUser(props.item.isSuperUser);
      let permissions = appContext.pageList.map(page => props.item.permissions.indexOf(page.id) >= 0);
      setPermissions(permissions);
    } else {
      setId(null);
      setItem(null);
      setEmail(null);
      setPermissions(null);
    }
  }, [appContext.pageList, props.item]);

  useEffect(() => {
    let match = email ? email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/) : false;
    setIsEmailValid(match && match.length > 0);
  }, [email])

  const onChangeEmail = evt => {
    setEmail(evt.currentTarget.value);
    setDirty(true);
  }

  const onChangeIsSuperUser = evt => {
    setIsSuperUser(evt.currentTarget.checked);
    setDirty(true);
  }

  const onAdd = () => {
    setIsNew(true);
    setId(getNewId(20));
    setEmail('');
    setIsSuperUser(false);
    setDirty(true);
    let permissions = appContext.pageList.map(page => false);
    setPermissions(permissions);
  }

  const onCancel = () => {
    if (isNew) {
      setId(null);
      props.onSelect(null);
    } else {
      setId(item.id);
      setEmail(item.email);
      setIsSuperUser(item.isSuperUser);
      let permissions = appContext.pageList.map(page => props.item.permissions.indexOf(page.id) >= 0);
      setPermissions(permissions);
    }
    setDirty(false);
  }

  const onDelete = () => {
    setConfirmOpen(true);
  }

  const deleteItem = () => {
    appContext.db.collection(SCHOOL).doc('users').collection('userList').doc(id).delete()
      .then(() => {
        setDirty(false);
        setConfirmOpen(false);
        props.clearSelectedItem()
      })
      .catch(err => {
        alert('Error deleting data');
        console.log(err);
      })
    props.onSelect(null);
  }

  const onSave = () => {
    let _permissions = permissions.map((p, idx) => {
      return p ? appContext.pageList[idx].id : ''
    });
    _permissions = _permissions.filter(p => p !== '');
    appContext.db.collection(SCHOOL).doc('users').update({lastUpdated: (new Date()).getTime()})
    appContext.db.collection(SCHOOL)
      .doc('users')
      .collection('userList')
      .doc(id)
      .set({id, email, isSuperUser, permissions: _permissions.join(',')})
      .then(() => {
        setDirty(false);
        props.onSelect(id);
      })
      .catch(err => {
        alert("Error saving data");
      })
    appContext.db.collection(appContext.school)
      .doc('users')
      .update({lastUpdated: (new Date()).getTime()})
  }

  const onChangePermission = evt => {
    let idx = evt.currentTarget.getAttribute('data-idx');
    let _permissions = permissions.slice(0);
    _permissions[idx] = !_permissions[idx];
    setPermissions(_permissions);
    setDirty(true);
  }

  const toggleConfirm = () => {
    setConfirmOpen(!setConfirmOpen);
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col md={3}>
            <Button className="button-separator" size='sm' onClick={onAdd} disabled={dirty}>Add</Button>
            {
              id ?
                <Button className="button-separator" size='sm' onClick={onDelete} disabled={dirty}>Delete</Button> : null
            }
          </Col>
          <Col md={6} className="text-center"><h4>Users</h4></Col>
          <Col md={3}>
            {
              id ?
                <div>
                  <Button className="button-separator" size='sm' onClick={onSave} disabled={!dirty || !isEmailValid}>Save</Button>
                  <Button className="button-separator" size='sm' onClick={onCancel} disabled={!dirty}>Cancel</Button>
                </div> : null
            }
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form>
          {
            id ?
              <>
                <Card>
                  <CardHeader>User</CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="tease">Email</Label>
                      <Input invalid={!isEmailValid} type="email" value={email} onChange={onChangeEmail} data-field="email"/>
                      <FormFeedback>A valid email is required</FormFeedback>
                      <FormText>This email must be tied to a Google acount</FormText>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" data-field="isSuperUser" checked={isSuperUser} onChange={onChangeIsSuperUser}/>
                        Check if this is a super-user
                      </Label>
                    </FormGroup>
                  </CardBody>
                </Card>
                {
                  !isSuperUser ?
                    <Card>
                      <CardHeader>Permissions</CardHeader>
                      <CardBody>
                        {
                          permissions.map((isEditor, idx) => {
                            return (<FormGroup check key={idx}>
                              <Label check>
                                <Input type="checkbox"
                                       key={idx}
                                       data-idx={idx}
                                       checked={isEditor}
                                       onChange={onChangePermission} />
                                {appContext.pageList[idx].text}
                              </Label>
                            </FormGroup>
                          )})
                        }
                      </CardBody>
                    </Card> : null
                }
              </>
              :
              <>
                <Card>
                  <CardBody>
                    <h5>Select an item</h5>
                  </CardBody>
                </Card>
              </>
          }
        </Form>
      </CardBody>
      <Modal isOpen={confirmOpen} toggle={toggleConfirm}>
        <ModalHeader toggle={toggleConfirm}>Confirm delete user</ModalHeader>
        <ModalBody><h5>Are you sure you want to delete this user?</h5></ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleConfirm}>No</Button>
        </ModalFooter>
      </Modal>
    </Card>
  );

}

export default UsersItem
