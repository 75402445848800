import React from 'react';
import { Row, Col, Button } from 'reactstrap';

const ImportWizardControls = props => {

  const LAST_STEP = 3;

  const onPrev = () => {
    props.setWizardStep(props.wizardStep - 1);
  }

  const onNext = () => {
    props.setWizardStep(props.wizardStep + 1);
  }

  return (
    <Row>
      <Col md={4}>
        <Button className="button-separator" size='sm' onClick={onPrev} disabled={props.wizardStep === 0}>&lt;--Prev</Button>
      </Col>
      <Col md={4} className="text-center">
        <Button className="button-separator" size='sm' onClick={props.toggleWizard}>Cancel import</Button>
      </Col>
      <Col md={4} className="text-right">
        <Button className="button-separator" size='sm' onClick={onNext} disabled={props.wizardStep === LAST_STEP}>{props.wizardStep === 2 ? 'Import' : 'Next-->'}</Button>
      </Col>
    </Row>
  )
}

export default ImportWizardControls;

