import React, { useContext } from 'react';
import AppContext from "./AppContext";
import api from "../store/api";
import { Input } from 'reactstrap';

const PageDropDown = props => {

  const appContext = useContext(AppContext);

  const onChangePage = ( {currentTarget} ) => {
    if (currentTarget.value === 'logout') {
      api.logout(appContext.firebase, appContext.dispatch);
    } else {
      props.onChangePage(currentTarget.value);
    }
  }

  return (
    <Input type="select" onChange={onChangePage} value={appContext.activePage}>
      <option key="">-- Select a page --</option>
      {
        appContext.pageList ? appContext.pageList.map(page => page.id !== 'users' ? <option key={page.id} value={page.id}>{page.text}</option> : null) : null
      }
      {
        appContext.user.isSuperUser ?
          <>
            <option key="@blank" disabled={true}>-------------------------</option>
            <option key="users" value="userPages">Page list</option>
            <option key="userPages" value="users">Users</option>
            <option key="sitemap" value="sitemap">Sitemap</option>
            <option key="system" value="system">System</option>
            <option key="backup-restore" value="backup-restore">Backup / Restore</option>
          </> : null
      }
      <option key="logout" value="logout">Logout</option>
    </Input>
  )

}

export default PageDropDown
