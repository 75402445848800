import React, { useRef, useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AppContext from "./AppContext";
const R = require('ramda');
const MAX_UPLOAD_SIZE = 500000;
const MAX_UPLOAD_SIZE_TEXT = '500Kb';

// TinyMCE editor file upload hooks information at
// https://www.tiny.cloud/docs/configure/file-image-upload/
// Can upload images, files, and media -- this system will only upload
// files
//
// React component reference at https://www.tiny.cloud/docs/integrations/react/#value
// and https://www.tiny.cloud/docs/integrations/react/#tinymcereacttechnicalreference
//
// Lots of hassles integrating the editor state in the React page.  The editor needs
// a 'ref' so the editor's dirty flag can be set / reset to follow the component's
// dirty flag.  This is done with a useEffect hook, listening for a change in the
// dirty flag status.
//
// Required props:
//  (str)  email:          User email
//  (str)  name:           User name
//  (str)  cloudRoot:      Cloud root folder for uploaded documents
//  (str)  value:          String to display on re-render
//  (obj)  storage:        Firebase storage reference
//  (fn)   onEditorChange: Callback with new content.  Note that the callback does NOT need
//                         set the dirty flag as TinyMCE will do that on the keyPress event
//  (fn)   setDirty:       Handler called with true parameter when text has been edited (flag)

const TinyMCE = props => {

  const appContext = useContext(AppContext);

  const getNewId = (n, chars='abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
    let hash = R.range(0, n).map(i => chars.charAt(parseInt(Math.random()*chars.length),10)).join('');
    return hash;
  }

  const API_KEY = 'c17eezm8x2x63vrsuxc9y27zrdfv6vvhirnghfcyz8jcsl8z';
  // Ref to the editor API
  let editorRef = useRef(null);

  const onKeyPress = evt => {
    props.setDirty(true);
  }

  const onClick = evt => {
    props.setDirty(true);
  }

  // Configure and upload the local file to Firebase storage
  const upload = (selectedFile, callback) => {
    let type = {
      contentType: selectedFile.type,
      customMetadata: {
        user: props.email,
        name: props.name
      }
    }
    // Get the filename and append a random 5-character string to the end
    let fnameArr = selectedFile.name.split('.');
    fnameArr[0] = fnameArr[0]+'_'+getNewId(5);
    let filename = fnameArr.join('.');

    // Set up a ref into the cloud storage in the <root>/ folder
    // and upload the file
    let ref = props.storage.ref().child(appContext.school+'/'+props.cloudRoot+'/'+filename);
    ref.put(selectedFile, type)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {
        // Populate the URL and
        callback(false, url);
      })
      .catch(err => {
        callback(true, err);
        // console.log(true, err);
      })
  }

  // TinyMCE editor configuration object
  const editorConfig = {
    height: 450,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      `bold italic underline forecolor backcolor link image | undo redo | fontselect fontsizeselect formatselect | 
      alignleft aligncenter alignright alignjustify | 
      bullist numlist outdent indent | removeformat | table | help`,

    file_picker_types: 'file image'/*image media*/,
    images_upload_handler: (blobInfo, success, failure, progress) => {
      let blob = blobInfo.blob();
      if (blob.size > MAX_UPLOAD_SIZE) {
        failure('File too large. Max size = '+MAX_UPLOAD_SIZE_TEXT, { remove: true });
      } else {
        upload(blob, (err, url) => {
          if (err) {
            failure(url);
          } else {
            success(url);
          }
        })
      }
    },
  }

  return (
    <Editor
      ref={editorRef}
      value={props.value}
      cloudChannel='5-testing'     // change to 5-testing until V5.7.1 is pushed
      apiKey={API_KEY}
      init={editorConfig}
      onEditorChange={props.onEditorChange}
      onKeyPress={onKeyPress}
      onClick={onClick}
    />
  )
}

export default TinyMCE;
