import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, ButtonGroup, Form, FormGroup, Input, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody } from 'reactstrap';
import AppContext from "../AppContext";
import ContactsControls from "./ContactsControls";
import { useCollection } from "react-firebase-hooks/firestore";
import ContactsList from "./ContactsList";
import newContact from "./newContact";
import DocumentList from "../DocumentList";

const R = require('ramda');

const Contacts = props => {

  const appContext = useContext(AppContext);

  const [mode, setMode] = useState('information');
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [item, setItem] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [noChangeAllowed, setNoChangeAllowed] = useState(false);

  const fieldLists = {
    information: [
      {caption: 'Title', name: 'title'},
      {caption: 'Text', name: 'text'}
    ],
    admin: [
      {caption: 'Title', name: 'title'},
      {caption: 'Name', name: 'name'},
      {caption: 'Email', name: 'email'},
      {caption: 'Extension', name: 'extension'}
    ],
    counselors: [
      {caption: 'Title', name: 'title'},
      {caption: 'Name', name: 'name'},
      {caption: 'Email', name: 'email'},
      {caption: 'Extension', name: 'extension'}
    ],
    support: [
      {caption: 'Title', name: 'title'},
      {caption: 'Name', name: 'name'},
      {caption: 'Email', name: 'email'},
      {caption: 'Extension', name: 'extension'}
    ],
    links: [
      {caption: 'Text', name: 'text'},
      {caption: 'URL', name: 'url'}
    ]
  }

  let ref = appContext.db.collection(appContext.school).doc('contacts');
  let [_list/*, loading, error*/] = useCollection(ref.collection(mode));
  let list = _list ? _list.docs.map(doc => {
    let data = doc.data();
    data.id = doc.id;
    return data;
  }).sort((a,b) => a.order < b.order ? -1 : 1) : [];

  useEffect(() => {
    if (selectedIdx !== null) {
      setItem(R.clone(list[selectedIdx]))
    }
  }, [selectedIdx]);

  useEffect(() => {
    setItem(null);
  }, [mode]);

  let maxItemOrder = list.reduce((max, item) => max === null || max < item.order ? item.order : max, null);

  const onChangeMode = evt => {
    setMode(evt.currentTarget.getAttribute('data-link'))
  }

  const onSelectedIdx = evt => {
    if (!dirty) {
      let idx = parseInt(evt.currentTarget.getAttribute('data-idx'), 10);
      setSelectedIdx(idx);
      setItem(R.clone(list[selectedIdx]));
    } else {
      setNoChangeAllowed(true);
      setTimeout(() => {
        setNoChangeAllowed(false);
      }, 3000);
    }
  }

  const clearSelectedIdx = () => {
    setSelectedIdx(null);
    setItem(null);
    setDirty(false);
  }

  const onChange = evt => {
    let field = evt.currentTarget.getAttribute('name');
    let value = evt.currentTarget.value;
    let _item = R.clone(item);
    _item[field] = value;
    setItem(_item);
    setDirty(true);
  }

  const onAdd = () => {
    let item = newContact(mode, list.length);
    item.order = maxItemOrder+1;
    setItem(item);
    setDirty(true);
  }

  const onCancel = () => {
    setItem(R.clone(list[selectedIdx]));
    setDirty(false);
  }

  const onDelete = () => {
    setIsConfirmOpen(true);
  }

  const deleteItem = () => {
    appContext.db.collection(appContext.school)
      .doc('contacts')
      .collection(mode)
      .doc(item.id).delete()
      .then(() => {
        setDirty(false);
        setIsConfirmOpen(false);
        clearSelectedIdx()
      })
      .catch(err => {
        alert('Error deleting data');
        console.log(err);
      })
  }

  const onSave = () => {
    appContext.db.collection(appContext.school)
      .doc('contacts')
      .collection(mode)
      .doc(item.id).set(item)
      .then(() => {
        setDirty(false);
        setSelectedIdx(item.order);
      })
      .catch(err => {
        alert("Error saving data");
      })
    appContext.db.collection(appContext.school)
      .doc('contacts')
      .update({lastUpdated: (new Date()).getTime()})
  }

  const isValid = () => {

  }

  const toggleIsConfirmOpen = () => {
    setIsConfirmOpen(!setIsConfirmOpen);
  }


  const getEditItems = (mode, item, fieldList) => {
    console.log(item);
    return (
      <Form>
        {
          fieldList.map(field => {
            return (
              <FormGroup key={field.name}>
                <Label for={field.name}>{field.caption}</Label>
                <Input type="text" name={field.name} value={item[field.name]} onChange={onChange} />
              </FormGroup>
            )
          })
        }
      </Form>
    );
  }

  let fieldList = fieldLists[mode];

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-center">
              <ButtonGroup>
                <Button onClick={onChangeMode} data-link="information" color={mode === 'information' ? 'primary' : 'secondary'}>Information</Button>
                <Button onClick={onChangeMode} data-link="admin" color={mode === 'admin' ? 'primary' : 'secondary'}>Administration</Button>
                <Button onClick={onChangeMode} data-link="counselors" color={mode === 'counselors' ? 'primary' : 'secondary'}>Counselors</Button>
                <Button onClick={onChangeMode} data-link="support" color={mode === 'support' ? 'primary' : 'secondary'}>Support staff</Button>
                {/*<Button onClick={onChangeMode} data-link="links" color={mode === 'links' ? 'primary' : 'secondary'}>Links</Button>*/}
                {/*<Button onClick={onChangeMode} data-link="documents" color={mode === 'documents' ? 'primary' : 'secondary'}>Documents</Button>*/}
              </ButtonGroup>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {
              mode !== 'documents' ?
                <>
                  <Col md={6}>
                    <ContactsList list={list}
                                  mode={mode}
                                  clearSelectedIdx={clearSelectedIdx}
                                  onSelectedIdx={onSelectedIdx} />
                  </Col>
                  <Col md={6}>
                    <Card>
                      <CardHeader>
                        <ContactsControls onAdd={onAdd}
                                          onCancel={onCancel}
                                          onDelete={onDelete}
                                          onSave={onSave}
                                          mode={mode}
                                          dirty={dirty}
                                          isValid={isValid}
                                          item={item}
                        />
                      </CardHeader>
                      <CardBody>
                        {
                          item ? getEditItems(mode, item, fieldList) : <h5>Select an item to edit</h5>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </> :
                <DocumentList page={appContext.activePage} storage={appContext.storage} />

            }
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={isConfirmOpen} toggle={toggleIsConfirmOpen}>
        <ModalHeader toggle={toggleIsConfirmOpen}>Confirm delete item</ModalHeader>
        <ModalBody><h5>Are you sure you want to delete this item?</h5></ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleIsConfirmOpen}>No</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={noChangeAllowed}>
        <ModalBody>Please save or cancel your changes before selecting a new item</ModalBody>
      </Modal>
    </>
  )
}

export default Contacts;
