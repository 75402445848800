import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Input, Form, FormGroup, Label, FormText,
  ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody, Badge } from 'reactstrap';
import AppContext from "./AppContext";

const System = props => {

  // Firebase URL is https://firebasestorage.googleapis.com/v0/b/phhs-website.appspot.com/o/<school>/<page>/<file list>
  // GCloud URL is https://storage.googleapis.com/phhs-website.appspot.com/<school>/<page>/<file list>
  // Format is https://storage.googleapis.com/<bucket>/<school>/<page>/<files>
  const appContext = useContext(AppContext);

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col md={12}>
            <h4>Please select a page...</h4>
          </Col>
        </Row>
      </CardHeader>
    </Card>
  )
}

export default System;
