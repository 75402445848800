const jwtDecode = require('jwt-decode');
const SCHOOL = require('../constants').SCHOOL;
const R = require('ramda');

const errorHandler = (dispatch, err) => {
  console.log(err);
  switch (err.code) {
    case 'domain:authUser:validateOAuth2':
      dispatch('AUTH:DOMAIN', err);
      break;
    case 'no-permission:authUser:getPermission':
      dispatch('AUTH:AUTHORIZATION', err);
      break;
    case 'firestore-error:authUser:notExists':
      dispatch('AUTH:INVALID_CREDENTIALS');
      break;
    default:
      dispatch('AUTH:SYSTEM_ERROR', err);
      break;
  }
}

const api = {

  logout: (firebase, dispatch) => {
    firebase.auth().signOut();
    dispatch('AUTH:LOGOUT');
  },

  auth_user: (firebase, dispatch, id_token) => {
    // const ROUTE = "http://localhost:5005/lop-phhs/us-central1/authUser";
    const ROUTE = `https://us-central1-phhs-website.cloudfunctions.net/get/${SCHOOL}/auth`;
    fetch(ROUTE, {
      method: "POST",
      mode: 'cors',
      cache: 'no-cache',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({id_token})
    })
      .then(response => response.json())
      .then(authToken => {
        if (authToken.err) {
          errorHandler(dispatch, authToken)
        } else {
          firebase.auth().signInWithCustomToken(authToken.payload).catch(function (error) {
            // Handle Errors here.
            errorHandler(dispatch, {
              err: true,
              code: 'firebase-signin',
              message: 'Unable to sign in to Firebase with provided token'
            })
            console.log(error);
          }).then(fbResult => {
            firebase.auth().currentUser.getIdTokenResult().then(r => {
              console.log("*** IdTokenResult ***")
              console.log(r);
              console.log("*** Claims ***")
              console.log(JSON.stringify(r.claims));
            })
            let claims = null;
            try {
              claims = jwtDecode(authToken.payload).claims;
              let db = firebase.firestore();
              let storage = firebase.storage();
              dispatch('AUTH:SUCCESS', {db, firebase, storage, id_token: authToken.payload, ...claims});
              api.getSystemData(db, claims.email, claims.isSuperUser, claims.permissions, dispatch);
            } catch (e) {
              errorHandler(dispatch, {err: true, code: 'token-error', message: 'Invalid JWT returned from Firebase'})
            }
          });
        }
      })
      .catch(err => {
        console.log(err);
        errorHandler(dispatch, err);
      })
  },

  getSystemData: async (db, email, isSuperUser, permissions, dispatch) => {
    db.collection(SCHOOL).onSnapshot(metadata => {
      metadata = R.mergeAll(metadata.docs.map(it => ({[it.id]: it.data()})));
      dispatch('DATA:SYSTEM', metadata.system);
      let pageList = Object.values(R.omit(['system'], metadata));
      pageList = pageList.filter(page => {
        return isSuperUser || permissions.indexOf(page.id) >= 0
      });
      pageList = pageList.sort((a,b) => a.text < b.text ? -1 : 1)
      dispatch('DATA:PAGE_LIST', pageList)
    }, error => {
      console.log(error);
      dispatch('AUTH:SYSTEM_ERROR', error);
    });

  }
}

export default api
