import React, { useEffect, useContext } from 'react';
import { Col, Card, CardHeader, CardBody,
  Form, FormGroup, FormText, FormFeedback, Label, Input } from 'reactstrap';
import format from "date-fns/format";
// import { Editor } from '@tinymce/tinymce-react';
import TinyMCE from "../TinyMCE";
import AppContext from "../AppContext";
// import getNewId from "../../utilities/getNewId";
const R = require('ramda');

const AnnBullItemEdit = props => {

  let appContext = useContext(AppContext);

  // Bulletin categories
  let bullCats = R.keys(appContext.bulletinCategories).reduce((acc, key) => {
    let c = {
      key,
      value: appContext.bulletinCategories[key]
    }
    acc.push(c);
    return acc;
  }, [])
  bullCats = bullCats.sort((a,b) => a.value < b.value ? -1 : 1);

  // // Ref to the editor API
  // let editorRef = useRef(null);
  //
  // Validation for tease and itemText
  let {item, setIsTeaseValid, setIsItemTextValid} = props
  useEffect(() => {
    setIsTeaseValid(item.tease.length > 0);
  }, [item.tease, setIsTeaseValid]);

  useEffect(() => {
    setIsItemTextValid(item.itemText.length > 0);
  }, [item.itemText, setIsItemTextValid]);

  // // Listen for changes in dirty so the editor's dirty flag can follow that state
  // useEffect(() => {
  //   if (editorRef.current && editorRef.current.editor) {
  //     editorRef.current.editor.setDirty(props.dirty);
  //   }
  // }, [props.dirty])
  //
  const onChange = evt => {
    let field = evt.currentTarget.getAttribute('data-field');
    let type = evt.currentTarget.type;
    let val = null;
    if (type === 'text' || type === 'number' || type === 'select-one' || type === 'textarea') {
      val = evt.currentTarget.value;
    } else if (type === 'checkbox') {
      val = evt.currentTarget.checked;
    } else if (type === 'radio') {
      val = evt.currentTarget.value;
    } else if (type === 'email') {
      val = evt.currentTarget.value;
    }
    props.onFieldChange({[field]: val});
  }

  const onEditorChange = content => {
    props.onEditorChange(content);
  }

  const onChangeDate = evt => {
    let field = evt.currentTarget.getAttribute('data-field');
    let dateVal = evt.currentTarget.value;
    if (dateVal !== '') {
      let dateStr = dateVal.substring(5, 7)+'/'+dateVal.substring(8, 10)+'/'+dateVal.substring(0,4);
      let newDate = (new Date(dateStr)).getTime();
      props.onFieldChange({[field]: newDate});
    }
  }

  return (
    <Form>
      <Card>
        <CardHeader><h5>Content</h5></CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="tease">Tease</Label>
            <Input invalid={!props.isTeaseValid} type="text" value={props.item.tease} onChange={onChange} data-field="tease"/>
            <FormFeedback>The tease is required</FormFeedback>
            <FormText>This will appear as the heading to your announcement and/or bulletin item</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="tease">Item text</Label>
            <Input className="hidden" type="textarea" readOnly value={props.item.itemText} />
            <TinyMCE email={appContext.user.email}
                     name={appContext.user.name}
                     cloudRoot='announcements'
                     storage={appContext.storage}
                     setDirty={props.setDirty}
                     onEditorChange={onEditorChange}
                     value={props.item.itemText}
             />
            <FormFeedback>The item text is required</FormFeedback>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox"
                     data-field="noTruncate"
                     onChange={onChange}
                     name="noTruncate"
                     id="noTruncate"
                     checked={props.item.noTruncate === undefined ? false : props.item.noTruncate}/>{' '}
              Do not truncate text
            </Label>
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardHeader><h5>Timing and Urgency</h5></CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="tease">Priority</Label>
            <Input type="number" value={props.item.priority} onChange={onChange} data-field="priority"/>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" data-field="urgent" checked={props.item.urgent} onChange={onChange}/>
              Urgent: Highlight and appear near the top
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" data-field="alwaysOn" checked={props.item.alwaysOn} onChange={onChange}/>
              Item should always appear
            </Label>
          </FormGroup>
          <Card>
            <CardBody className={props.item.alwaysOn ? 'controls-disabled' : ''}>
              <FormGroup>
                <Label for="tease">Date on</Label>
                <Input type="date" value={format(new Date(props.item.dateOn), 'yyyy-MM-dd')} onChange={onChangeDate}
                       required data-field="dateOn"/>
                <FormText>The item will appear on this date</FormText>
              </FormGroup>
              <FormGroup>
                <Label for="tease">Date off</Label>
                <Input type="date" value={format(new Date(props.item.dateOff), 'yyyy-MM-dd')} onChange={onChangeDate}
                       required data-field="dateOff"/>
                <FormText>The item will disappear after this date</FormText>
              </FormGroup>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <Card>
        <CardHeader><h5>Bulletin</h5></CardHeader>
        <CardBody>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" data-field="onBulletin" checked={props.item.onBulletin} onChange={onChange}/>
              Appears on the bulletin
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="bulletinCategory">Bulletin category</Label>
            <Input type="select" data-field="bulletinCategory" value={props.item.bulletinCategory} onChange={onChange}>
              {
                bullCats.map((cat, idx) => {
                  return <option key={'cat' + cat.key} value={cat.key}>{cat.value}</option>
                })
              }
            </Input>
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardHeader><h5>Announcements</h5></CardHeader>
        <CardBody>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" data-field="onAnnouncement" checked={props.item.onAnnouncement}
                     onChange={onChange}/>
              Appears in the announcements
            </Label>
          </FormGroup>
        </CardBody>
      </Card>
      {/*<Card>*/}
      {/*  <CardHeader><h5>Bulletin / Announcements Priority</h5></CardHeader>*/}
      {/*  <CardBody className={!props.item.onAnnouncement || !props.item.onBulletin ? 'controls-disabled' : ''}>*/}
      {/*    <FormGroup tag="fieldset" row>*/}
      {/*      <legend className="col-form-label col-md-12">When the announcements and bulletin appear on the same*/}
      {/*        webpage, where should this item appear?*/}
      {/*      </legend>*/}
      {/*      <Col md={10}>*/}
      {/*        <FormGroup check>*/}
      {/*          <Label check>*/}
      {/*            <Input type="radio" disabled={!props.item.onAnnouncement || !props.item.onBulletin}*/}
      {/*                   data-field="locationPriority" name="locationPriority" value="announcements"*/}
      {/*                   checked={props.item.locationPriority === 'announcements'} onChange={onChange}/>*/}
      {/*            Announcements*/}
      {/*          </Label>*/}
      {/*        </FormGroup>*/}
      {/*        <FormGroup check>*/}
      {/*          <Label check>*/}
      {/*            <Input type="radio" disabled={!props.item.onAnnouncement || !props.item.onBulletin}*/}
      {/*                   data-field="locationPriority" name="locationPriority" value="bulletin"*/}
      {/*                   checked={props.item.locationPriority === 'bulletin'} onChange={onChange}/>*/}
      {/*            Bulletin*/}
      {/*          </Label>*/}
      {/*        </FormGroup>*/}
      {/*      </Col>*/}
      {/*    </FormGroup>*/}
      {/*  </CardBody>*/}
      {/*</Card>*/}
      <Card>
        <CardHeader><h5>Contact</h5></CardHeader>
        <CardBody>
          <FormGroup>
            <Label for="contact">Contact person</Label>
            <Input type="text" value={props.item.contact} onChange={onChange} data-field="contact"/>
            <FormText>This person will appear as the contact for more information</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="contactEmail">Contact email</Label>
            <Input type="email" value={props.item.contactEmail} onChange={onChange}
                   data-field="contactEmail"/>
          </FormGroup>
        </CardBody>
      </Card>
    </Form>
  )
}

export default AnnBullItemEdit;
