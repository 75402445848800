import React, {useContext} from 'react';
import AnnBull from './AnnBull/AnnBull';
import Users from './Users/Users';
import Contacts from './Contacts/Contacts';
import StaffDirectory from './StaffDirectory/StaffDirectory';
import Page from './Page/Page';
import Pictures from './Pictures';
import System from "./System";
import UserPages from "./UserPages/UserPages";
import SelectPage from "./SelectPage";
import Sitemap from './Sitemap';
import AppContext from "./AppContext";

const Main = props => {

  let appContext = useContext(AppContext);

  const getComponent = () => {
    let comp = null
    switch (appContext.activePage) {
      case 'announcements':
        comp = <AnnBull />
        break;

      case 'users':
        comp = <Users />
        break;

      case 'contacts':
        comp = <Contacts />
        break;

      case 'staffDirectory':
        comp = <StaffDirectory />
        break;

      case 'userPages':
        comp = <UserPages />
        break;

      case 'pictures':
        comp = <Pictures />
        break;

      case 'system':
        comp = <System />
        break;

      case '':
        comp = <SelectPage />
        break;

      case 'backup-restore':
        window.location.href = 'https://restore.patrickhenryhs.net';
        break;

      case 'sitemap':
        comp = <Sitemap />
        break;

      default:
        comp = <Page page={appContext.activePage} />
        break;
    }
    return comp;
  }

  return getComponent()

}

export default Main
