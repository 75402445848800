import React, { useContext } from 'react';
import { Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import AppContext from "../AppContext";
import upArrow from '../../icons/arrow-thick-top.svg';
import downArrow from '../../icons/arrow-thick-bottom.svg';

const R = require('ramda');

const PageList = props => {

  const appContext = useContext(AppContext);

  const move = (items, id, direction) => {
    let thisOneIdx = R.findIndex(it => it.id === id, items);
    let thisOne = items[thisOneIdx];
    let otherOne = items[thisOneIdx + direction];
    let temp = otherOne.order;
    otherOne.order = thisOne.order;
    thisOne.order = temp;
    appContext.db.collection(appContext.school)
      .doc(appContext.activePage)
      .collection(props.mode)
      .doc(otherOne.id)
      .set(otherOne);
    appContext.db.collection(appContext.school)
      .doc(appContext.activePage)
      .collection(props.mode)
      .doc(thisOne.id)
      .set(thisOne);
    // props.clearSelectedId();
  }

  const moveUp = evt => {
    let id = evt.currentTarget.getAttribute('data-id')
    move(props.items, id, -1);
  }

  const moveDown = evt => {
    let id = evt.currentTarget.getAttribute('data-id')
    move(props.items, id, 1);
  }

  const getListItems = (mode, onSelectedIdx) => {
    switch (mode) {
      case 'items':
        return (
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col md={2} className="contacts-header text-center">Move</Col>
                <Col md={10} className="contacts-header">Item</Col>
              </Row>
            </ListGroupItem>
            {
              props.items.map((it, idx) => (
                <ListGroupItem key={it.id}
                               active={it.id === props.selectedId}>
                  <Row>
                    <Col md={2} className="text-center">
                      {
                        it.order > 0 ?
                          <img src={upArrow}
                               alt="Move up"
                               className='arrow-icon'
                               onClick={moveUp}
                               data-id={it.id}
                          /> : null
                      }
                      {' '}
                      {
                        idx < props.items.length-1 ?
                          <img src={downArrow}
                               alt="Move down"
                               className='arrow-icon'
                               onClick={moveDown}
                               data-id={it.id}
                          /> : null
                      }
                    </Col>
                    <Col md={10}
                         onClick={props.onSelectedItemClick}
                         className="make-inline"
                         data-id={it.id}
                         // dangerouslySetInnerHTML={{__html: it.header+'-'+it.title+': '+it.text}}
                    >{it.header+' - '+it.title+' - '+it.subtitle}
                    </Col>
                  </Row>
                </ListGroupItem>
              ))
            }
          </ListGroup>
        );

      case 'links':
        return (
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col md={2} className="contacts-header text-center">Move</Col>
                <Col md={10} className="contacts-header">Item</Col>
              </Row>
            </ListGroupItem>
            {props.items.map((it, idx) =>
              <ListGroupItem key={'item'+idx}>
                <Row>
                  <Col md={2} className="text-center">
                    {
                      it.order > 0 ?
                        <img src={upArrow}
                             alt="Move up"
                             className='arrow-icon'
                             onClick={moveUp}
                             data-id={it.id}
                        /> : null
                    }
                    {' '}
                    {
                      idx < props.items.length-1 ?
                        <img src={downArrow}
                             alt="Move down"
                             className='arrow-icon'
                             onClick={moveDown}
                             data-id={it.id}
                        /> : null
                    }
                  </Col>
                  <Col md={10}
                       onClick={props.onSelectedItemClick}
                       className="make-inline"
                       data-id={it.id}
                  >
                    <span dangerouslySetInnerHTML={{__html: it.text+' ('+it.indent+')   '}}></span>
                    <a href={it.url} target="_blank" rel="noopener noreferrer">Link</a>
                  </Col>
                </Row>
              </ListGroupItem>)}
          </ListGroup>
        );

      default:
        return null;
    }
  }

  return (
    <Card>
      <CardHeader>
        <h5>Item list</h5>
      </CardHeader>
      <CardBody>
        {getListItems(props.mode, props.selectedId)}
      </CardBody>
    </Card>
  )
}

export default PageList;
