import React, {useContext, useEffect} from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody } from 'reactstrap';
import {useCollectionDataOnce} from "react-firebase-hooks/firestore";
import AppContext from "./AppContext";

// Top node for Editing announcements and bulletin items
const Sitemap = () => {

  const appContext = useContext(AppContext);

  // Retrieve the pages...
  let ref = appContext.db.collection(appContext.school);
  let [pages/*, pLoading, pError*/] = useCollectionDataOnce(ref);
  console.log(pages);

  const onCopy = () => {
    let sitemap = document.getElementById('textarea');
    sitemap.select();
    document.execCommand('copy');
    alert("Sitemap was copied");
  }

  if (pages) {
    let sitemap = pages.map(page => "https://patrickhenryhs.net/"+page.id);
    return (
      <Card>
        <CardHeader>
          <h3 className="text-center">Pagelist</h3>
          <h5 className="text-center">Copy / paste this list to /public/sitemap_index.txt, go to Google Search Console, click Sitemaps and submit new sitemap.</h5>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={{size: 8, offset: 2}}>
              <textarea id="textarea" className="sitemap">
                {sitemap.join('\r\n')}
              </textarea>
            </Col>
            <Col md={2}>
              <Button size='sm' onClick={onCopy}>Copy</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  } else {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-center">
              <h5>Loading website page information</h5>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    )
  }
}

export default Sitemap
