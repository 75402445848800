import getNewId from "../../utilities/getNewId";
const ID_SIZE = 20;

export default () => {
  return {
    id: getNewId(ID_SIZE),
    text: '',
    lastUpdated: (new Date()).getTime(),
    static: false,
    noTruncate: false
  }
}

