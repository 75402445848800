import addDays from "date-fns/addDays";
import getNewId from "../../utilities/getNewId";
const ONE_WEEK = 7;
const ID_SIZE = 20;

export default (name, email) => {
  const now = new Date();
  return {
    alwaysOn: false,
    bulletinCategory: 0,
    contact: name,
    contactEmail: email,
    dateOn: now.getTime(),
    dateOff: addDays(now, ONE_WEEK).getTime(),
    id: getNewId(ID_SIZE),
    itemText: '',
    locationPriority: 'bulletin',     // or 'announcements'
    onAnnouncement: false,
    onBulletin: true,
    priority: 0,
    showInArchive: true,
    tease: '',
    urgent: false,
  }
}

