import React, { useEffect, useState, useContext } from 'react';
import {
  Row, Col, Button, Card, CardHeader, CardBody,
  Form, FormGroup, FormText, FormFeedback, Label, Input,
  Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon
} from 'reactstrap';
import format from "date-fns/format";
import TinyMCE from "../TinyMCE";
import AppContext from "../AppContext";
import getNewId from "../../utilities/getNewId";
import constants from "../../constants";
const ID_SIZE = 20;
const ONE_WEEK = 7*24*60*60*1000;

const PageItemEdit = props => {

  let appContext = useContext(AppContext);

  // Fields for item
  const [header, setHeader] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [title, setTitle] = useState('');
  const [noTruncate, setNoTruncate] = useState(false);

  // Fields for link
  const [indent, setIndent] = useState(0);
  const [url, setUrl] = useState('');

  // Fields for items and links
  const [alwaysOn, setAlwaysOn] = useState(false);
  const [dateOff, setDateOff] = useState(null);
  const [dateOn, setDateOn] = useState(null);
  const [id, setId] = useState('');
  const [order, setOrder] = useState(0);
  const [text, setText] = useState('');
  const [urgent, setUrgent] = useState(false);

  // Validation
  const [isHeaderValid, setIsHeaderValid] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isSubtitleValid, setIsSubtitleValid] = useState(true);
  const [isTextValid, setIsTextValid] = useState(false);
  // const [isUrlValid, setIsUrlValid] = useState(false);
  const [isValid, setIsValid] = useState(false);

  // UI
  const [newId, setNewId] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  let propsItem = props.item;
  useEffect(() => {
    if (newId) return;
    if (!propsItem) {
      setId(null);
    } else {
      setAlwaysOn(propsItem.alwaysOn);
      setDateOff(propsItem.dateOff);
      setDateOn(propsItem.dateOn);
      setId(propsItem.id);
      setOrder(propsItem.order);
      setText(propsItem.text);
      setUrgent(propsItem.urgent);
      if (props.mode === 'items') {
        setHeader(propsItem.header);
        setSubtitle(propsItem.subtitle);
        setTitle(propsItem.title);
        setNoTruncate(propsItem.noTruncate);
      } else {
        setIndent(propsItem.indent);
        setUrl(propsItem.url);
      }
    }
    props.setDirty(false);
  }, [propsItem, forceReload, newId])

  useEffect(() => {
    setIsTextValid(text.length > 0);
    if (props.mode === 'items') {
      setIsHeaderValid(header.length > 0);
      // setIsTitleValid(title.length > 0);
      // setIsSubtitleValid(subtitle.length > 0);
    } else if (props.mode === 'links') {
      // setIsUrlValid(url.length > 0);
    }
  }, [header, title, subtitle, text, url, props.mode]);

  useEffect(() => {
    if (props.mode === 'items') {
      setIsValid(isHeaderValid && /*isTitleValid && isSubtitleValid &&*/ isTextValid);
    } else if (props.mode === 'links') {
      setIsValid(isTextValid/* && isUrlValid*/);
    }
  }, [isHeaderValid, isTitleValid, isSubtitleValid, isTextValid, /*isUrlValid, */props.mode]);

  const onChangeHandler = (setter, val) => {
    props.setDirty(true);
    setter(val);
  }

  const onEditorChange = content => {
    setText(content);
  }

  const onChangeDate = (setter, evt) => {
    let dateVal = evt.currentTarget.value;
    if (dateVal !== '') {
      props.setDirty(true);
      let dateStr = dateVal.substring(5, 7)+'/'+dateVal.substring(8, 10)+'/'+dateVal.substring(0,4);
      let newDate = (new Date(dateStr)).getTime();
      setter(newDate);
    }
  }

  const onClickTruncate = evt => {
    setNoTruncate(!noTruncate);
    props.setDirty(true);
  }

  const toggleIsConfirmOpen = () => {
    setIsConfirmOpen(!setIsConfirmOpen);
  };

  const assembleItem = mode => {
    if (mode === 'items') {
      return {alwaysOn, dateOff, dateOn, header, id, order, subtitle, text, title, urgent, noTruncate}
    } else if (mode === 'links') {
      return {alwaysOn, dateOff, dateOn, id, indent, order, text, urgent, url}
    }
  }

  const onCancel = () => {
    setNewId(false);
    setForceReload(!forceReload);
  };

  const onAdd = () => {
    setNewId(true);
    setAlwaysOn(true);
    setDateOff((new Date()).getTime()+ONE_WEEK);
    setDateOn((new Date()).getTime());
    setId(getNewId(ID_SIZE));
    let nextProp;
    if (props.mode === 'links') {
      nextProp = 'nextLink';
    } else {
      nextProp = 'nextItem';
    }
    setOrder(props.maxOrder+1);
    setUrgent(false);
    setText('');
    if (props.mode === 'items') {
      setHeader('');
      setSubtitle('');
      setTitle('');
      setNoTruncate(false);
    } else if (props.mode === 'links') {
      setIndent(0);
      setUrl('');
    } else {
      return null;
    }
    props.clearSelectedId();
    props.setDirty(true);
  }

  const onSave = () => {
    let item = assembleItem(props.mode);
    let nextProp;
    if (props.mode === 'link') {
      nextProp = 'nextLink';
    } else {
      nextProp = 'nextItem';
    }
    appContext.db.collection(appContext.school)
      .doc(appContext.activePage)
      .collection(props.mode)
      .doc(item.id).set(item)
      .then(() => {
        setNewId(false);
        props.setDirty(false);
        props.setSelectedId(item.id);
      })
      .catch(err => {
        alert("Error saving data");
      });
    appContext.db.collection(appContext.school)
      .doc(appContext.activePage)
      .update({
        lastUpdated: (new Date()).getTime(),
        [nextProp]: item.order+1
      })
  }

  const onDelete = () => {
    setIsConfirmOpen(true);
  };

  const onCopy = () => {
    let copyText = document.querySelector("#pageURL");
    copyText.select();
    document.execCommand("copy");
  }

  const deleteItem = () => {
    appContext.db.collection(appContext.school)
      .doc(appContext.activePage)
      .collection(props.mode)
      .doc(id).delete()
      .then(() => {
        props.setDirty(false);
        setIsConfirmOpen(false);
        setId('');
        props.clearSelectedId()
      })
      .catch(err => {
        alert('Error deleting data');
        console.log(err);
      })
  }

  const getEditForm = () => {
    switch (props.mode) {
      case 'items':
        return (
          <Form>
            <Card>
              <CardHeader><h5>Content</h5></CardHeader>
              <CardBody>
                <FormGroup>
                  <Label for="pageURL">Page URL</Label>
                  <InputGroup>
                    <Input type="text" name="pageURL" id="pageURL" readOnly className="unshaded-input" value={constants.URL+'/'+id} />
                    <InputGroupAddon addonType="append">
                      <Button onClick={onCopy}>Copy</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="header">Header</Label>
                  <Input invalid={!isHeaderValid} type="text" value={header} onChange={evt => onChangeHandler(setHeader, evt.currentTarget.value)} />
                  <FormFeedback>The header is required</FormFeedback>
                  <FormText>This will appear as the heading to your web page item</FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="title">Title</Label>
                  <Input invalid={!isTitleValid} type="text" value={title} onChange={evt => onChangeHandler(setTitle, evt.currentTarget.value)} />
                  <FormFeedback>The header is required</FormFeedback>
                  <FormText>This will appear as the title to your web page item</FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="subtitle">Subtitle</Label>
                  <Input invalid={!isSubtitleValid} type="text" value={subtitle} onChange={evt => onChangeHandler(setSubtitle, evt.currentTarget.value)} />
                  <FormFeedback>The subtitle is required</FormFeedback>
                  <FormText>This will appear as the subtitle to your web page item</FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="text">Text</Label>
                  <Input invalid={!isTextValid} className="hidden" type="textarea" readOnly value={text} />
                  <TinyMCE
                    email={appContext.user.email}
                    name={appContext.user.name}
                    cloudRoot={id}
                    storage={appContext.storage}
                    onEditorChange={onEditorChange}
                    setDirty={props.setDirty}
                    value={text}
                  />
                  <FormFeedback>The item text is required</FormFeedback>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox"
                           onChange={onClickTruncate}
                           name="noTruncate"
                           id="noTruncate"
                           checked={noTruncate}/>{' '}
                    Do not truncate text
                  </Label>
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardHeader><h5>Timing and Urgency</h5></CardHeader>
              <CardBody>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" checked={urgent} onChange={evt => onChangeHandler(setUrgent, evt.currentTarget.checked)} />
                    Urgent: Highlight and place near the top
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" checked={alwaysOn} onChange={evt => onChangeHandler(setAlwaysOn, evt.currentTarget.checked)} />
                    Item should always appear
                  </Label>
                </FormGroup>
                <Card>
                  <CardBody className={alwaysOn ? 'controls-disabled' : ''}>
                    <FormGroup>
                      <Label for="tease">Date on</Label>
                      <Input type="date" required value={format(new Date(dateOn), 'yyyy-MM-dd')} onChange={evt => onChangeDate(setDateOn, evt)} />
                      <FormText>The item will appear on this date</FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label for="tease">Date off</Label>
                      <Input type="date" required value={format(new Date(dateOff), 'yyyy-MM-dd')} onChange={evt => onChangeDate(setDateOff, evt)} />
                      <FormText>The item will disappear after this date</FormText>
                    </FormGroup>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Form>
        )

      case 'links':
        return (
          <Form>
            <Card>
              <CardHeader><h5>Content</h5></CardHeader>
              <CardBody>
                <FormGroup>
                  <Label for="text">Text</Label>
                  <Input invalid={!isTextValid} type="text" value={text} onChange={evt => onChangeHandler(setText, evt.currentTarget.value)} />
                  <FormFeedback>The item text is required</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="url">URL</Label>
                  <Input type="url" value={url} onChange={evt => onChangeHandler(setUrl, evt.currentTarget.value)} />
                  <FormText>The link will point to this URL. Leave blank to create a link subject divider</FormText>
                </FormGroup>
                <FormGroup>
                  <Label for="indent">Indent</Label>
                  <Input type="number" value={indent} onChange={evt => onChangeHandler(setIndent, evt.currentTarget.value)} />
                  <FormText>Indent the link this number of tab stops</FormText>
                </FormGroup>
              </CardBody>
            </Card>
            <Card>
              <CardHeader><h5>Timing and Urgency</h5></CardHeader>
              <CardBody>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" checked={urgent} onChange={evt => onChangeHandler(setUrgent, evt.currentTarget.checked)} />
                    Urgent: Highlight and place near the top
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" checked={alwaysOn} onChange={evt => onChangeHandler(setAlwaysOn, evt.currentTarget.checked)} />
                    Item should always appear
                  </Label>
                </FormGroup>
                <Card>
                  <CardBody className={alwaysOn ? 'controls-disabled' : ''}>
                    <FormGroup>
                      <Label for="tease">Date on</Label>
                      <Input type="date" required value={format(new Date(dateOn), 'yyyy-MM-dd')} onChange={evt => onChangeDate(setDateOn, evt)} />
                      <FormText>The item will appear on this date</FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label for="tease">Date off</Label>
                      <Input type="date" required value={format(new Date(dateOff), 'yyyy-MM-dd')} onChange={evt => onChangeDate(setDateOff, evt)} />
                      <FormText>The item will disappear after this date</FormText>
                    </FormGroup>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Form>
        )

      default:
        return null
    }
  };

  return (
    <>
      {
        id ?
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col md={6}>
                      <Button className="button-separator" size='sm' onClick={onAdd} disabled={props.dirty}>Add</Button>
                      <Button className="button-separator" size='sm' onClick={onDelete} disabled={id.length === 0 || newId}>Delete</Button>
                    </Col>
                    <Col md={6} className="text-right">
                      <Button className="button-separator" size='sm' onClick={onSave}
                              disabled={!props.dirty || !isValid}>Save</Button>
                      <Button className="button-separator" size='sm' onClick={onCancel}
                              disabled={!props.dirty}>Cancel</Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          :
          <Row>
            <Col md={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col md={6}>
                      <Button className="button-separator" size='sm' onClick={onAdd} disabled={props.dirty}>Add</Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
      }
      <Row>
        <Col md={12}>
        {id ? getEditForm() : null}
        </Col>
      </Row>
      <Modal isOpen={isConfirmOpen} toggle={toggleIsConfirmOpen}>
        <ModalHeader toggle={toggleIsConfirmOpen}>Confirm delete item</ModalHeader>
        <ModalBody><h5>Are you sure you want to delete this item?</h5></ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleIsConfirmOpen}>No</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default PageItemEdit;
