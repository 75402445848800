import React, { useContext } from 'react';
import { Row, Col, Card, CardHeader, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import AppContext from "../AppContext";
import upArrow from '../../icons/arrow-thick-top.svg';
import downArrow from '../../icons/arrow-thick-bottom.svg';

const ContactsList = props => {

  const appContext = useContext(AppContext);

  const move = (idx, direction) => {
    let otherOne = props.list[idx+direction];
    let thisOne = props.list[idx];
    let temp = otherOne.order;
    otherOne.order = thisOne.order;
    thisOne.order = temp;
    appContext.db.collection(appContext.school)
      .doc('contacts')
      .collection(props.mode)
      .doc(otherOne.id)
      .set(otherOne);
    appContext.db.collection(appContext.school)
      .doc('contacts')
      .collection(props.mode)
      .doc(thisOne.id)
      .set(thisOne);
    props.clearSelectedIdx();
  }

  const moveUp = evt => {
    let idx = parseInt(evt.currentTarget.getAttribute('data-idx'),10);
    move(idx, -1);
  }

  const moveDown = evt => {
    let idx = parseInt(evt.currentTarget.getAttribute('data-idx'),10);
    move(idx, 1);
  }

  const getListItems = (mode, onSelectedIdx) => {
    switch (mode) {
      case 'information':
        return (
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col md={2} className="contacts-header">Move</Col>
                <Col md={10} className="contacts-header">Item</Col>
              </Row>
            </ListGroupItem>
            {props.list.map((it, idx) =>
              <ListGroupItem key={'item'+idx}>
                <Row>
                  <Col md={2} className="text-center">
                    {idx > 0 ? <img src={upArrow} alt="Move up" className='arrow-icon' onClick={moveUp} data-idx={idx} /> : null}
                    {' '}
                    {idx < props.list.length-1 ? <img src={downArrow} alt="Move down" className='arrow-icon' onClick={moveDown} data-idx={idx} /> : null}
                  </Col>
                  <Col md={10} onClick={onSelectedIdx} data-idx={idx}>
                    {it.title}: {it.text}
                  </Col>
                </Row>
              </ListGroupItem>)}
          </ListGroup>
        );

      case 'admin':
      case 'counselors':
      case 'support':
        return (
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col md={2} className="contacts-header">Move</Col>
                <Col md={10} className="contacts-header">Item</Col>
              </Row>
            </ListGroupItem>
            {props.list.map((it, idx) =>
              <ListGroupItem key={'item'+idx}>
                <Row>
                  <Col md={2} className="text-center">
                    {idx > 0 ? <img src={upArrow} alt="Move up" className='arrow-icon' onClick={moveUp} data-idx={idx} /> : null}
                    {' '}
                    {idx < props.list.length-1 ? <img src={downArrow} alt="Move down" className='arrow-icon' onClick={moveDown} data-idx={idx} /> : null}
                  </Col>
                  <Col md={10} onClick={onSelectedIdx} data-idx={idx}>
                    {it.title}: {it.name} {it.extension ? '(ext '+it.extension+')' : ''}
                  </Col>
                </Row>
              </ListGroupItem>)}
          </ListGroup>
        );

      case 'links':
        return (
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col md={2} className="contacts-header">Move</Col>
                <Col md={10} className="contacts-header">Item</Col>
              </Row>
            </ListGroupItem>
            {props.list.map((it, idx) =>
              <ListGroupItem key={'item'+idx}>
                <Row>
                  <Col md={2} className="text-center">
                    {idx > 0 ? <img src={upArrow} alt="Move up" className='arrow-icon' onClick={moveUp} data-idx={idx} /> : null}
                    {' '}
                    {idx < props.list.length-1 ? <img src={downArrow} alt="Move down" className='arrow-icon' onClick={moveDown} data-idx={idx} /> : null}
                  </Col>
                  <Col md={10} onClick={onSelectedIdx} data-idx={idx}>
                    {it.text}: {it.url}
                  </Col>
                </Row>
              </ListGroupItem>)}
          </ListGroup>
        );

      default:
        return null;
    }
  }

  return (
    <Card>
      <CardHeader>
        <h5>Item list</h5>
      </CardHeader>
      <CardBody>
        {
          props.list ? getListItems(props.mode, props.onSelectedIdx) : <h5>No items to display</h5>
        }
      </CardBody>
    </Card>
  )
}

export default ContactsList;
