import React, {useState, useContext, useEffect } from 'react';
import { Row, Col, Toast, ToastHeader, ToastBody, Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody } from 'reactstrap';
import AnnBullList from "./AnnBullList";
import AnnBullControls from "./AnnBullControls";
import AnnBullItemEdit from './AnnBullItemEdit';
import AnnBullItemDisplay from './AnnBullItemDisplay';
import newAnnBull from "./newAnnBull";
import AppContext from "../AppContext";
import { useCollection } from 'react-firebase-hooks/firestore';
import DocumentList from "../DocumentList";
const R = require('ramda');

// Top node for Editing announcements and bulletin items
const AnnBull = () => {

  let appContext = useContext(AppContext);

  const [itemKey, setItemKey] = useState(null);
  const [item, setItem] = useState(null);
  const [item$, setItem$] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [dirty, setDirty] = useState(false);
  const [mode, setMode] = useState('edit');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isTeaseValid, setIsTeaseValid] = useState(true);
  const [isItemTextValid, setIsItemTextValid] = useState(true);
  const [noChangeAllowed, setNoChangeAllowed] = useState(false);
  const [forceReloadItem, setForceReloadItem] = useState(false);

  const fieldsToFilter = ['tease','itemText','contact','contactEmail'];
  // let editorRef = useRef(null);

  let ref = appContext.db.collection(appContext.school).doc('announcements').collection('items');
  let [_list, loading, error] = useCollection(ref);
  let list = _list ? _list.docs.map(it => it.data()) : [];
  list = list.sort((a, b) => a.dateOn < b.dateOn ? 1 : -1);

  useEffect(() => {
    let i = itemKey && list ? R.find(it => it.id === itemKey, list) : null;
    setItem(i);
    setItem$(R.clone(i));
  }, [itemKey, forceReloadItem]);

  useEffect(() => {
    setIsValid(isTeaseValid && isItemTextValid);
  }, [isTeaseValid, isItemTextValid])

  const onSelect = id => {
    if (!dirty) {
      setItemKey(id);
      setDirty(false);
    } else {
      setNoChangeAllowed(true);
      setTimeout(() => {
        setNoChangeAllowed(false);
      }, 3000);
    }
  }

  const onAdd = () => {
    setItem(newAnnBull(appContext.user.name, appContext.user.email));
    setDirty(true);
  }

  const onCancel = () => {
    let item = R.clone(item$);
    setItem(item);
    setDirty(false);
  }

  const onDelete = () => {
    setIsConfirmOpen(true);
  }

  const deleteItem = () => {
    appContext.db.collection(appContext.school)
      .doc('announcements')
      .collection('items')
      .doc(item.id).delete()
      .then(() => {
        setDirty(false);
        setIsConfirmOpen(false);
        clearSelectedItem()
      })
      .catch(err => {
        alert('Error deleting data');
        console.log(err);
      })
  }

  const onSave = () => {
    let _item = R.omit(['dateOffStr','dateOnStr'], item);
    appContext.db.collection(appContext.school)
      .doc('announcements')
      .collection('items')
      .doc(_item.id).set(_item)
      .then(() => {
        setDirty(false);
        setItemKey(_item.id);
        setForceReloadItem(!forceReloadItem);
      })
      .catch(err => {
        alert("Error saving data");
      })
    appContext.db.collection(appContext.school)
      .doc('announcements')
      .update({lastUpdated: (new Date()).getTime()})
  }

  const clearSelectedItem = () => {
    setItemKey(null);
  }

  const toggleIsConfirmOpen = () => {
    setIsConfirmOpen(!setIsConfirmOpen);
  }

  const onFieldChange = newObj => {
    let _item = R.clone(item);
    _item = {..._item, ...newObj}
    setItem(_item);
    setDirty(true);
  }

  const onEditorChange = content => {
    let _item = R.clone(item);
    _item.itemText = content
    setItem(_item);
  }

  const onEditorDirty = () => {
    setDirty(true);
  }

  const onChangeView = evt => {
    setMode(evt.currentTarget.getAttribute('data-mode'));
  }

  let editOrDisplay = null
  if (item && mode === 'display') {
    editOrDisplay = <AnnBullItemDisplay item={item} />
  } else if (item && mode === 'edit') {
    editOrDisplay = <AnnBullItemEdit item={item}
                                     dirty={dirty}
                                     setDirty={setDirty}
                                     itemKey={itemKey}
                                     // editorRef={editorRef}
                                     onFieldChange={onFieldChange}
                                     onEditorChange={onEditorChange}
                                     onEditorDirty={onEditorDirty}
                                     setIsTeaseValid={setIsTeaseValid}
                                     setIsItemTextValid={setIsItemTextValid}
                                     isTeaseValid={isTeaseValid}
                                     isItemTextValid={isItemTextValid}
                                     setIsValue={setIsValid}
                    />
  } else if (item && mode === 'documents') {
    editOrDisplay = <Row><DocumentList page={appContext.activePage} storage={appContext.storage} /></Row>
  }

  const listRender = it => (
    <Toast>
      <ToastHeader icon={it.urgent ? 'warning' : null}>{it.tease}</ToastHeader>
      <ToastBody className="item-list-body">{it.itemText.substring(0, 200)+(it.itemText.length>200 ? '...' : '')}</ToastBody>
    </Toast>
  )

  if (loading) {
    return <Row><Col md={12}><h4 className="text-center loading-separator">Loading...</h4></Col></Row>
  } else if (error) {
    return <Row><Col md={12}><h4>Error loading the announcements and bulletin items</h4></Col></Row>
  } else {
    return (
      <Row>
        <Col md={4}>
          <AnnBullList title={'Announcement / Bulletin List'}
                       list={list}
                       itemKey={itemKey}
                       onSelect={onSelect}
                       fieldsToFilter={fieldsToFilter}
                       render={listRender}
          />
        </Col>
        <Col md={8}>
          <Card>
            <CardHeader>
              <AnnBullControls onAdd={onAdd}
                               onCancel={onCancel}
                               onDelete={onDelete}
                               onSave={onSave}
                               mode={mode}
                               dirty={dirty}
                               isValid={isValid}
                               onChangeView={onChangeView}
                               item={item}
              />
            </CardHeader>
            <CardBody>
              {
                item ? editOrDisplay : null
              }
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={isConfirmOpen} toggle={toggleIsConfirmOpen}>
          <ModalHeader toggle={toggleIsConfirmOpen}>Confirm delete item</ModalHeader>
          <ModalBody><h5>Are you sure you want to delete this item?</h5></ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
            <Button color="secondary" onClick={toggleIsConfirmOpen}>No</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={noChangeAllowed}>
          <ModalBody>Please save or cancel your changes before selecting a new item</ModalBody>
        </Modal>
      </Row>
    )
  }
}

export default AnnBull
