import React, {useContext, useEffect, useState} from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, ButtonGroup } from 'reactstrap';
import { useCollection, useDocumentData } from "react-firebase-hooks/firestore";
import AppContext from "../AppContext";
import PageItems from "./PageItems";
import DocumentList from '../DocumentList'
const R = require('ramda');

// Top node for Editing announcements and bulletin items
const Page = props => {

  const appContext = useContext(AppContext);

  const [mode, setMode] = useState('items');
  let pageObj = R.find(page => page.id === appContext.activePage, appContext.pageList)
  let pageName = pageObj ? pageObj.text : 'Unknown page';

  // Retrieve the items...
  let ref = appContext.db.collection(appContext.school).doc(appContext.activePage);
  let [items/*, iLoading, iError*/] = useCollection(ref.collection('items'));
  items = items ? items.docs.map(doc => {
    let data = doc.data();
    data.id = doc.id;
    return data;
  }).sort((a,b) => a.order < b.order ? -1 : 1) : null;
  items = items ? items.map(item => {
    if (item.noTruncate === undefined) {
      item.noTruncate = false;
    }
    return item;
  }) : null;

  // Retrieve the metadata for this page
  let docRef = appContext.db.collection(appContext.school).doc(appContext.activePage);
  let [metadata/*, mLoading, mError*/] = useDocumentData(docRef);

  // ... and the links for this page
  let [links/*, lLoading, lError*/] = useCollection(ref.collection('links'));
  links = links ? links.docs.map(doc => {
    let data = doc.data();
    data.id = doc.id;
    return data;
  }).sort((a,b) => a.order < b.order ? -1 : 1) : null;

  const onChangeMode = evt => {
    setMode(evt.currentTarget.getAttribute('data-link'))
  }

  let comp;
  if (mode === 'items' || mode === 'links') {
    comp = <PageItems mode={mode}
                      metadata={metadata}
                      items={mode === 'items' ? items : links}
    />
  } else {
    comp = <DocumentList page={appContext.activePage}
                         metadata={metadata}
                         storage={appContext.storage}
    />
  }

  if (links && items) {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-center">
              <ButtonGroup>
                <Button onClick={onChangeMode} data-link="links"
                        color={mode === 'links' ? 'primary' : 'secondary'}>Links</Button>
                <Button onClick={onChangeMode} data-link="items"
                        color={mode === 'items' ? 'primary' : 'secondary'}>Items</Button>
                <Button onClick={onChangeMode} data-link="docs"
                        color={mode === 'docs' ? 'primary' : 'secondary'}>Document list</Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {comp}
          </Row>
        </CardBody>
      </Card>
    )
  } else {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-center">
              <h5>Loading page information</h5>
            </Col>
          </Row>
        </CardHeader>
      </Card>
    )
  }
}

export default Page
