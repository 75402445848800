import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Input, Form, FormGroup, FormFeedback, Label, FormText,
  ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody, Badge } from 'reactstrap';
import AppContext from "./AppContext";
import getNewId from "../utilities/getNewId";
import format from "date-fns/format";

const MAX_UPLOAD_SIZE = 500000;
const MAX_UPLOAD_SIZE_TEXT = '500Kb';

// Properties: page:     bucket name in which these documents are stored
//             storage:  Firebase storage ref
const Pictures = props => {

  // Firebase URL is https://firebasestorage.googleapis.com/v0/b/phhs-website.appspot.com/o/<school>/<page>/<file list>
  // GCloud URL is https://storage.googleapis.com/phhs-website.appspot.com/<school>/<page>/<file list>
  // Format is https://storage.googleapis.com/<bucket>/<school>/<page>/<files>
  const appContext = useContext(AppContext);
  const URL = 'https://www.googleapis.com/storage/v1/b/phhs-website.appspot.com/o?delimiter=/&prefix='+appContext.school+'/pictures/';
  const ROOT = 'https://storage.googleapis.com';
  const ONE_WEEK = 7*24*60*60*1000 - 1;

  const [list, setList] = useState([]);
  const [url, setUrl] = useState(null);
  const [item, setItem] = useState(null);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [reload, setReload] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const [mimeType, setMimeType] = useState('');
  const [dateOn, setDateOn] = useState((new Date()).getTime());
  const [dateOff, setDateOff] = useState((new Date()).getTime()+ONE_WEEK);
  const [alwaysOn, setAlwaysOn] = useState(false);
  const [altText, setAltText] = useState('');
  const [caption, setCaption] = useState('');
  const [altTextValid, setAltTextValid] = useState(false);
  const [captionValid, setCaptionValid] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [tooBig, setTooBig] = useState(false);

  useEffect(() => {
    setAltTextValid(altText.length > 0);
    setCaptionValid(caption.length > 0);
  }, [altText, caption])

  useEffect(() => {
    setIsValid(altTextValid && uploadFile && (mimeType.indexOf('image/') >= 0) /* && captionValid */);
  }, [altTextValid, /*captionValid, */ uploadFile, mimeType])

  useEffect( () => {
    console.log(URL);
    fetch(URL)
      .then(data => data.json())
      .then(({items}) => {
        console.log(items);
        let filter = appContext.school+'/pictures/';
        let _list = items.map((item, idx) => {
          if (item.name === filter) {
            return null
          } else {
            return {
              src: ROOT + '/' + item.bucket + '/' + item.name,
              date: item.updated,
              user: item.metadata.user,
              path: item.name,
              name: item.metadata.name,
              altText: item.metadata.altText,
              caption: item.metadata.caption,
              dateOn: parseInt(item.metadata.dateOn, 10),
              dateOff: parseInt(item.metadata.dateOff, 10),
              alwaysOn: item.metadata.alwaysOn === 'true'
            }
          }
        });
        _list = _list.filter(item => item !== null);
        setList(_list);
      })
      .catch(err => {
        console.log('Carousel pictures fetch error');
        console.log(err);
      })
  }, [reload])

  const onClickDocument = evt => {
    setUrl(evt.currentTarget.getAttribute('data-url'));
    setSelectedIdx(evt.currentTarget.getAttribute('data-idx'));
    setItem(list[evt.currentTarget.getAttribute('data-idx')]);
  };

  const onDeleteClick = evt => {
    setDeleteModal(true);
  };

  const toggleDeleteModal = evt => {
    setDeleteModal(!deleteModal);
  }

  const deleteItem = evt => {
    let item = list[selectedIdx];
    appContext.storage.ref()
      .child(item.path)
      .delete()
      .then(() => {
        setDeleteModal(false);
        setSelectedIdx(null);
        setReload(!reload);
        setUrl(null);
      })
      .catch(err => {
        console.log('Error deleting the file')
        console.log(err);
      })
    appContext.db.collection(appContext.school)
      .doc('pictures')
      .update({lastUpdated: (new Date()).getTime()})
  }

  const upload = () => {
    let el = document.getElementById("file");
    el.click();
  }

  const onFileChange = evt => {
    setTooBig(evt.target.files[0].size > MAX_UPLOAD_SIZE);
    if (evt.target.files[0].size > MAX_UPLOAD_SIZE) return;
    setUploadFile(evt.target.files[0]);
    setMimeType(evt.target.files[0].type)
  }

  const onUpload = evt => {
    setUploading(true);
    let type = {
      contentType: uploadFile.type,
      customMetadata: {
        user: appContext.user.email,
        name: appContext.user.name,
        altText,
        caption,
        dateOn,
        dateOff,
        alwaysOn
      }
    };
    let fnameArr = uploadFile.name.split('.');
    fnameArr[0] = fnameArr[0]+'_'+getNewId(5);
    let filename = fnameArr.join('.');
    let ref = appContext.storage.ref().child(appContext.school+'/'+appContext.activePage+'/'+filename);
    ref.put(uploadFile, type)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {
        setReload(!reload);
        setUploading(false);
        setDateOn((new Date()).getTime());
        setDateOff((new Date()).getTime()+ONE_WEEK);
        setAlwaysOn(false);
        setAltText('');
        setCaption('');
        toggleModal();
      })
      .catch(err => {
        console.log('Upload error');
        console.log(err);
        setUploading(false);
        setUploadModal(false);
        toggleModal();
      })
    appContext.db.collection(appContext.school)
      .doc('pictures')
      .update({lastUpdated: (new Date()).getTime()})
  }

  const convertDate = evt => {
    let dateVal = evt.currentTarget.value;
    let dateStr = dateVal.substring(5, 7)+'/'+dateVal.substring(8, 10)+'/'+dateVal.substring(0,4);
    return (new Date(dateStr)).getTime();
  };

  const onChangeHandler = (setter, val) => {
    setter(val);
  }

  const onCopy = evt => {
    let url = evt.currentTarget.getAttribute('data-url');
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  }

  const openModal = () => {
    setUploadModal(true);
  }

  const toggleModal = () => {
    setUploadModal(!openModal);
  }

  return (
    <>
      <Row>
        <Col md={4}>
          <Card>
            <CardHeader>
              <Row>
                <Col md={2}>
                  <span></span>
                </Col>
                <Col md={5} className="text-right">
                  <Button size="sm" block onClick={onDeleteClick} className="button-separator">Delete</Button>
                </Col>
                <Col md={5}>
                  <Button block onClick={openModal} size="sm">{uploading ? 'Uploading...' : 'Upload'}</Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ListGroup>
              {
                list.map((it, idx) => {
                  let path = it.src.split('/');
                  let fn = path[path.length-1];
                  return (
                    <ListGroupItem active={selectedIdx+''===idx+''} key={fn+idx} onClick={onClickDocument} data-url={it.src} data-idx={idx}>
                      <Row>
                        <Col md={9}>
                          <p>{fn}</p>
                        </Col>
                        <Col md={3}>
                          <Badge color="secondary" className="text-right" onClick={onCopy} data-url={it.src}>Copy link</Badge>
                        </Col>
                      </Row>
                    </ListGroupItem>
                  )
                })
              }
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <CardHeader>
              {
                !url ? <h5>Select a picture</h5> :
                  <Row>
                    <Col md={4}>
                      <p>Date: {format(new Date(item.date), 'MMM d, yyyy')}</p>
                      <p>Uploaded by: {item.user}</p>
                      <p>Name: {item.name}</p>
                    </Col>
                    <Col md={4} className="text-center">
                      <p>Alt text: {item.altText}</p>
                      <p>Caption: {item.caption}</p>
                    </Col>
                    <Col md={4} className="text-right">
                      <p>{item.alwaysOn ? 'Always visible' : 'On: '+format(new Date(item.dateOn), 'MMM d, yyyy')}</p>
                      <p>{item.alwaysOn ? '' : 'Off: '+format(new Date(item.dateOff), 'MMM d, yyyy')}</p>
                    </Col>
                  </Row>
              }
            </CardHeader>
            <CardBody>
              {
                url ?
                  <img id="document"
                       title="Document"
                       width="100%"
                       alt={item.altText}
                       src={url}>
                  </img> : null
              }
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
          <ModalHeader toggle={toggleDeleteModal}>Confirm delete item</ModalHeader>
          <ModalBody><h5>Are you sure you want to delete this item?</h5></ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
            <Button color="secondary" onClick={toggleDeleteModal}>No</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={linkCopied}>
          <ModalHeader>Link copied!</ModalHeader>
        </Modal>
        <Modal size='lg' isOpen={uploadModal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>{uploading ? "Uploading..." : 'Upload carousel picture'}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup row>
                <Col md={4}>
                  <Button size="sm" onClick={upload}>Image to upload</Button>
                </Col>
                <Col md={8}>
                  <p className="upload-file-name">{uploadFile ? 'File to upload: '+uploadFile.name : ''}</p>
                  {
                    mimeType.length > 0 && mimeType.indexOf('image/') === -1 ?
                      <p className="error">The file you're uploading must be an image</p> : null
                  }
                  {
                    tooBig ?
                      <p className="error">The file is too big. Max = {MAX_UPLOAD_SIZE_TEXT}</p> : null
                  }
                </Col>
                <Input type="file" style={{display:'none'}} name="file" id="file" onChange={onFileChange} />
                <FormFeedback>The file is required</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="altText">Text to describe the image</Label>
                <Input type="text" value={altText} onChange={evt => setAltText(evt.currentTarget.value)} name="altText" data-field='altText' />
                <FormText>This information is displayed when the image file is not available and also for assistive devices for the visually impaired</FormText>
                <FormFeedback>The image description is required</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="caption">Caption</Label>
                <Input type="text" value={caption} onChange={evt => setCaption(evt.currentTarget.value)} name="caption" data-field='caption' />
                <FormFeedback>The caption is required</FormFeedback>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" data-field="alwaysOn" checked={alwaysOn} onChange={evt => setAlwaysOn(evt.currentTarget.checked)}/>
                  Picture should always appear
                </Label>
              </FormGroup>
              <Card>
                <CardBody className={alwaysOn ? 'controls-disabled' : ''}>
                  <FormGroup>
                    <Label for="tease">Date on</Label>
                    <Input type="date" value={format(new Date(dateOn), 'yyyy-MM-dd')} required onChange={evt => onChangeHandler(setDateOn, convertDate(evt))}
                           required data-field="dateOn"/>
                    <FormText>The picture will appear on this date</FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="tease">Date off</Label>
                    <Input type="date" value={format(new Date(dateOff), 'yyyy-MM-dd')} required onChange={evt => onChangeHandler(setDateOff, convertDate(evt))}
                           required data-field="dateOff"/>
                    <FormText>The picture will disappear after this date</FormText>
                  </FormGroup>
                </CardBody>
              </Card>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" onClick={onUpload} disabled={!isValid}>Upload</Button>
            <Button size="sm" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </Row>
    </>
  )
}

export default Pictures;
