import React from 'react';
import { Row, Col, Button } from 'reactstrap';

// Top node for Editing announcements and bulletin items
const ContactsControls = props => {

  if (props.item) {
    return (
      <Row>
        <Col md={6}>
          <Button className="button-separator" size='sm' onClick={props.onAdd} disabled={props.dirty}>Add</Button>
          <Button className="button-separator" size='sm' onClick={props.onDelete} disabled={props.dirty}>Delete</Button>
        </Col>
        <Col md={6} className="text-right">
          <Button className="button-separator" size='sm' onClick={props.onSave}
                  disabled={!props.dirty || !props.isValid}>Save</Button>
          <Button className="button-separator" size='sm' onClick={props.onCancel}
                  disabled={!props.dirty}>Cancel</Button>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row>
        <Col md={3}>
          <Button className="button-separator" size='sm' onClick={props.onAdd} disabled={props.dirty}>Add</Button>
        </Col>
      </Row>
    )
  }
}

export default ContactsControls
