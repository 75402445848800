import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Input,
  ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody, Badge } from 'reactstrap';
import AppContext from "./AppContext";
import getNewId from "../utilities/getNewId";

const MAX_UPLOAD_SIZE = 500000;
const MAX_UPLOAD_SIZE_TEXT = '500Kb';

// Properties: page:     bucket name in which these documents are stored
//             storage:  Firebase storage ref
const DocumentList = props => {

  // Firebase URL is https://firebasestorage.googleapis.com/v0/b/phhs-website.appspot.com/o/<page>/<file list>
  // GCloud URL is https://storage.googleapis.com/phhs-website.appspot.com/<page>/<file list>
  // Format is https://storage.googleapis.com/<bucket>/<page>/<files>
  const appContext = useContext(AppContext);
  const HOST = 'https://storage.googleapis.com';

  const [list, setList] = useState([]);
  const [url, setUrl] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [reload, setReload] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [tooBig, setTooBig] = useState(false);

  useEffect( () => {
    props.storage.ref()
      .child(appContext.school+'/'+props.page)
      .list()
      .then(res => {
        let _list = res.items.reduce((_list, it) => {
          _list.push({bucket: it.bucket, fullPath: it.fullPath})
          return _list;
        }, []);
        setList(_list);
      })
      .catch(err => {
        console.log('Error retrieving the document list')
        console.log(err);
      })
  }, [reload, props.page, props.storage]);

  const onClickDocument = evt => {
    setUrl(evt.currentTarget.getAttribute('data-url'));
    setSelectedIdx(evt.currentTarget.getAttribute('data-idx'));
  };

  const onDeleteClick = evt => {
    setDeleteModal(true);
  };

  const toggleDeleteModal = evt => {
    setDeleteModal(!deleteModal);
  }

  const deleteItem = evt => {
    let item = list[selectedIdx];
    props.storage.ref()
      .child(item.fullPath)
      .delete()
      .then(() => {
        setDeleteModal(false);
        setReload(!reload);
      })
      .catch(err => {
        console.log('Error deleting the file')
        console.log(err);
      })
  }

  const selectFile = () => {
    let el = document.getElementById("file");
    el.click();
  }

  const onFileChange = evt => {
    setUploading(true);
    let selectedFile = evt.target.files[0]
    setTooBig(selectedFile.size > MAX_UPLOAD_SIZE);
    if (selectedFile.size > MAX_UPLOAD_SIZE) {
      setUploading(false);
      return;
    }
    let type = {
      contentType: selectedFile.type,
      customMetadata: {
        user: appContext.user.email,
        name: appContext.user.name
      }
    }
    let fnameArr = selectedFile.name.split('.');
    fnameArr[0] = fnameArr[0]+'_'+getNewId(5);
    let filename = fnameArr.join('.');
    let ref = appContext.storage.ref().child(appContext.school+'/'+appContext.activePage+'/'+filename);
    ref.put(selectedFile, type)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then(url => {
        setReload(!reload);
        setUploading(false);
      })
      .catch(err => {
        console.log('Upload error');
        console.log(err);
        setUploading(false);
      })
  }

  const onCopy = evt => {
    let url = evt.currentTarget.getAttribute('data-url');
    const el = document.createElement('textarea');
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  }

  return (
    <>
      <Col md={4}>
        <Card>
          <CardHeader>
            <Row>
              <Col md={2}>
                <span></span>
              </Col>
              <Col md={5} className="text-right">
                <Button size="sm" block onClick={onDeleteClick} className="button-separator">Delete</Button>
              </Col>
              <Col md={5}>
                <Input type="file" style={{display:'none'}} name="file" id="file" onChange={onFileChange} />
                <Button block onClick={selectFile} size="sm">{uploading ? 'Uploading...' : 'Upload'}</Button>
              </Col>
            </Row>
            {
              tooBig ?
                <Row>
                  <Col>
                    <h6 className="text-center error list-top-separator">File is too big (max={MAX_UPLOAD_SIZE_TEXT})</h6>
                  </Col>
                </Row>: null
            }
          </CardHeader>
          <CardBody>
            <ListGroup>
            {
              list.map((it, idx) => {
                let path = it.fullPath.split('/');
                let fn = path[path.length-1];
                let url = HOST+'/'+it.bucket+'/'+it.fullPath;
                return (
                  <ListGroupItem active={selectedIdx+''===idx+''} key={fn+idx} onClick={onClickDocument} data-url={url} data-idx={idx}>
                    <Row>
                      <Col md={9}>
                        <p>{fn}</p>
                      </Col>
                      <Col md={3}>
                        <Badge color="secondary" className="text-right" onClick={onCopy} data-url={url}>Copy</Badge>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )
              })
            }
            </ListGroup>
          </CardBody>
        </Card>
      </Col>
      <Col md={8}>
        <iframe id="document"
                title="Document"
                width="100%"
                height="600px"
                src={url}>
        </iframe>
      </Col>
      <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
        <ModalHeader toggle={toggleDeleteModal}>Confirm delete item</ModalHeader>
        <ModalBody><h5>Are you sure you want to delete this item?</h5></ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleDeleteModal}>No</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={linkCopied}>
        <ModalHeader>Link copied!</ModalHeader>
      </Modal>
    </>
  )
}

export default DocumentList;
