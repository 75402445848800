import React, { useState } from 'react';
import {Row, Col, ListGroup, ListGroupItem, Card, CardHeader, CardBody,
  Form, FormGroup, Label, Input} from 'reactstrap';

const R = require('ramda');

// Announcement / bulletin item list
const ItemList = props => {

  const [filterText, setFilterText] = useState('');

  let list = filterText.length > 0 ? props.list.filter(it => {
    return R.any(b => b, props.fieldsToFilter.map(field => {
      return it[field].toLowerCase().indexOf(filterText.toLowerCase()) >= 0
    }))
  }) : props.list

  if (props.enableShowArchived) {
    if (!props.showArchived) {
      const date = (new Date()).getTime();
      list = list.filter(it => it.alwaysOn || (date <= it.dateOff));
    }
    list = list.sort((a,b) => a.dateOff < b.dateOff ? -1 : 1);
  }


  const onClick = evt => {
    props.onSelect(evt.currentTarget.getAttribute('data-id'));
  }

  const onChange = evt => {
    setFilterText(evt.currentTarget.value);
  }

  return props.list ?
    <Card>
      <CardHeader className="text-center">
        <h5>{props.title}</h5>
        {
          props.enableShowArchived ?
            <div><Input type="checkbox" checked={props.showArchived} onChange={props.onArchivedClick} />{' '}Show archived</div> : null
        }
      </CardHeader>
      <CardBody>
        {
          props.fieldsToFilter ?
            <Form>
              <FormGroup row className="form-top-adjust">
                <Label md={{offset:1, size:3}} for="filterText">Filter:</Label>
                <Col md={7}>
                  <Input name="filterText" id="filterText" type="text" onChange={onChange} value={filterText} />
                </Col>
              </FormGroup>
            </Form> : null
        }
        <Row>
          <Col md={12}>
            <ListGroup className="list-group">
              {
                list.map(it => <ListGroupItem
                  data-id={it.id}
                  key={it.id}
                  onClick={onClick}
                  active={it.id === props.itemKey}>
                    {props.render(it)}
                </ListGroupItem>)
              }
            </ListGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
    :
    <Card>
      <CardHeader>{props.title}</CardHeader>
      <CardBody>
        <h5>Loading...</h5>
      </CardBody>
    </Card>
}

export default ItemList
