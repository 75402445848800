import React from 'react';
// import GoogleLogin from 'react-google-login'
import GoogleSignin from "./GoogleSignin";
import pinwheel from '../assets/preloader.gif'
import api from '../store/api'

const Google = props => {

  const CLIENT_ID = '749785223064-b4ditijk8n02ksq3137t4ifekudreu0v.apps.googleusercontent.com';

  // const onSuccess = user => {
  const onSuccess = token => {
    // let id_token = user.getAuthResponse().id_token
    props.dispatch('AUTH:IN_PROCESS');
    // api.auth_user(props.firebase, props.dispatch, id_token);
    api.auth_user(props.firebase, props.dispatch, token);
  };

  const onFailure = evt => {
    console.log(evt);
    props.dispatch('AUTH:INVALID_CREDENTIALS')
  };

  const onRequest = () => {

  };

  const options = {width: 250}
  return (
    <div className="text-center">
      {
        (props.inProcess) ?
          <div><img className="pinwheel" src={pinwheel} alt="Loading..." /></div> :
          <GoogleSignin clientId={CLIENT_ID}
                        signinCallback={onSuccess}
                        options={options}
                        errorCallback={onFailure} />
          // <GoogleLogin clientId={CLIENT_ID}
          //              tag="div"
          //              type=""
          //              style={{}}
          //              theme="dark"
          //              autoLoad={false}
          //              disabled={props.inProcess}
          //              isSignedIn={true}
          //              onSuccess={onSuccess}
          //              onFailure={onFailure}
          //              onRequest={onRequest}>
          // </GoogleLogin>
      }
    </div>
  )
}

export default Google
