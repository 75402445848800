const { SCHOOL } = require('../constants');

module.exports = () => ({
  school: SCHOOL,
  inProcess: false,
  isLoggedIn: false,
  domainError: false,
  invalidCredentials: false,
  timeout: false,
  systemError: false,
  db: null,
  id_token: '',
  user: null,
  activePage: '',
  pageId: '',

  system: null,
  bulletinCategories: null,

  reasons: []
})
