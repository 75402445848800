import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button, Input, Form, FormGroup, Label, FormText,
  ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody, Badge } from 'reactstrap';
import AppContext from "./AppContext";
import getNewId from "../utilities/getNewId";
import format from "date-fns/format";
import {useDocumentData} from "react-firebase-hooks/firestore";

const ONE_DAY = 24*60*60*1000;

// Properties: page:     bucket name in which these documents are stored
//             storage:  Firebase storage ref
const System = props => {

  // Firebase URL is https://firebasestorage.googleapis.com/v0/b/phhs-website.appspot.com/o/<school>/<page>/<file list>
  // GCloud URL is https://storage.googleapis.com/phhs-website.appspot.com/<school>/<page>/<file list>
  // Format is https://storage.googleapis.com/<bucket>/<school>/<page>/<files>
  const appContext = useContext(AppContext);

  const [mission, setMission] = useState('');
  const [principal, setPrincipal] = useState('');
  const [schoolAddress, setSchoolAddress] = useState('');
  const [schoolCity, setSchoolCity] = useState('');
  const [schoolPhone, setSchoolPhone] = useState('');
  const [schoolFax, setSchoolFax] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [technologyCoordinator, setTechnologyCoordinator] = useState('');
  const [technologyCoordinatorEmail, setTechnologyCoordinatorEmail] = useState('');
  const [webmaster, setWebmaster] = useState('');
  const [webmasterEmail, setWebmasterEmail] = useState('');
  const [forceReload, setForceReload] = useState(false);
  const [dirty, setDirty] = useState(false);

  let {information} = appContext;
  useEffect( () => {
    setMission(information.MISSION);
    setPrincipal(information.PRINCIPAL);
    setSchoolAddress(information.SCHOOL_ADDRESS);
    setSchoolCity(information.SCHOOL_CITY);
    setSchoolFax(information.SCHOOL_FAX);
    setSchoolPhone(information.SCHOOL_PHONE);
    setSchoolName(information.SCHOOL_NAME);
    setTechnologyCoordinator(information.TECHNOLOGY_COORDINATOR);
    setTechnologyCoordinatorEmail(information.TECHNOLOGY_COORDINATOR_EMAIL);
    setWebmaster(information.WEBMASTER);
    setWebmasterEmail(information.WEBMASTER_EMAIL);
  }, [information, forceReload]);

  const onChange = (setter, value) => {
    setter(value);
    setDirty(true);
  }

  const onSave = () => {
    let obj = {
      information: {
        MISSION: mission,
        PRINCIPAL: principal,
        SCHOOL_ADDRESS: schoolAddress,
        SCHOOL_NAME: schoolName,
        SCHOOL_CITY: schoolCity,
        SCHOOL_PHONE: schoolPhone,
        SCHOOL_FAX: schoolFax,
        TECHNOLOGY_COORDINATOR: technologyCoordinator,
        TECHNOLOGY_COORDINATOR_EMAIL: technologyCoordinatorEmail,
        WEBMASTER: webmaster,
        WEBMASTER_EMAIL: webmasterEmail
      }
    };
    appContext.db
      .collection(appContext.school)
      .doc('system')
      .update(obj)
      .then(() => setDirty(false));
  };

  const onCancel = () => {
    setForceReload(!forceReload);
    setDirty(false);
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col md={6}>
            <h5>System information</h5>
          </Col>
          <Col md={6} className="text-right">
            <Button size="sm" onClick={onSave} disabled={!dirty} className="button-separator">Save</Button>
            <Button size="sm" onClick={onCancel} disabled={!dirty}>Cancel</Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
          <Form>
            <FormGroup>
              <Label for="name">School name:</Label>
              <Input type="text" value={schoolName} onChange={evt => onChange(setSchoolName, evt.currentTarget.value)} name="name" />
            </FormGroup>
            <FormGroup>
              <Label for="address">Street address:</Label>
              <Input type="text" value={schoolAddress} onChange={evt => onChange(setSchoolAddress, evt.currentTarget.value)} name="address" />
            </FormGroup>
            <FormGroup>
              <Label for="city">City:</Label>
              <Input type="text" value={schoolCity} onChange={evt => onChange(setSchoolCity, evt.currentTarget.value)} name="city" />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone:</Label>
              <Input type="text" value={schoolPhone} onChange={evt => onChange(setSchoolPhone, evt.currentTarget.value)} name="phone" />
            </FormGroup>
            <FormGroup>
              <Label for="fax">Fax:</Label>
              <Input type="text" value={schoolFax} onChange={evt => onChange(setSchoolFax, evt.currentTarget.value)} name="fax" />
            </FormGroup>
            <FormGroup>
              <Label for="mission">Mission:</Label>
              <Input className="mission-textarea" type="textarea" value={mission} onChange={evt => onChange(setMission, evt.currentTarget.value)} name="mission" />
            </FormGroup>
          </Form>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="principal">Principal <span style={{fontStyle: 'italic', fontSize: '0.85em'}}>(Note that the Principal must also be changed on the "Contacts" page)</span>:</Label>
              <Input type="text" value={principal} onChange={evt => onChange(setPrincipal, evt.currentTarget.value)} name="principal" />
            </FormGroup>
            <FormGroup>
              <Label for="tc">Technology coordinator:</Label>
              <Input type="text" value={technologyCoordinator} onChange={evt => onChange(setTechnologyCoordinator, evt.currentTarget.value)} name="tc" />
            </FormGroup>
            <FormGroup>
              <Label for="tce">Technology coordinator email:</Label>
              <Input type="text" value={technologyCoordinatorEmail} onChange={evt => onChange(setTechnologyCoordinatorEmail, evt.currentTarget.value)} name="tce" />
            </FormGroup>
            <FormGroup>
              <Label for="webmaster">Webmaster:</Label>
              <Input type="text" value={webmaster} onChange={evt => onChange(setWebmaster, evt.currentTarget.value)} name="webmaster" />
            </FormGroup>
            <FormGroup>
              <Label for="webmasterEmail">Webmaster email:</Label>
              <Input type="text" value={webmasterEmail} onChange={evt => onChange(setWebmasterEmail, evt.currentTarget.value)} name="webmasterEmail" />
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default System;
