import React from 'react';
import {Row, Col, Card, CardHeader, CardBody, CardText, CardFooter} from 'reactstrap';

// Announcement item
const AnnouncementItem = it => {

  return (
    <Card>
      <CardHeader><h5>Announcement</h5></CardHeader>
      <CardBody>
        <Card>
          <CardHeader className="announcement-header">
            {it.tease.toUpperCase()}
          </CardHeader>
          <CardBody>
            <CardText dangerouslySetInnerHTML={{__html: it.itemText}}></CardText>
          </CardBody>
          <CardFooter className="announcement-footer">
            <Row>
              <Col xs={6}>
                <a href={'mailto:' + it.contactEmail}>{it.contact}</a>
              </Col>
              <Col xs={6} className="text-right">
                <span className="announcement-date-on-off">{it.dateOnStr} - {it.dateOffStr}</span>
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </CardBody>
    </Card>
  )
}

export default AnnouncementItem;

