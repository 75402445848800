import React, { useContext } from 'react';
import AppContext from "./AppContext";
import { Col, Row } from 'reactstrap';
import PageDropDown from "./PageDropDown";

const HeaderLoggedIn = props => {

  const appContext = useContext(AppContext);

  const onChangePage = newPage => {
    appContext.dispatch('NAV:GOTO_PAGE', newPage);
  }

  return (
    <Row className="header">
      <Col md={7}>
        <h1 className="header-title">{appContext.school.toUpperCase()} Website Maintenance</h1>
      </Col>
      <Col md={2} className="text-right">
        <h1 className="header-title">Page to edit: </h1>
      </Col>
      <Col md={3}>
        <PageDropDown onChangePage={onChangePage}/>
      </Col>
    </Row>
  )

}

export default HeaderLoggedIn
