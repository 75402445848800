import React, { useReducer, useEffect } from 'react'
import Main from './views/Main'
import HeaderLoggedIn from './views/HeaderLoggedIn'
import HeaderNotLoggedIn from './views/HeaderNotLoggedIn'
import Footer from './views/Footer'
import IENotSupported from './views/IENotSupported'
import AppContext from "./views/AppContext";
import {Card, CardHeader} from 'reactstrap'
import isIE from './utilities/isIE';

import reducer from './store/reducer';
import getInitialState from './store/getInitialState';

const App = props => {

  const [state, _dispatch] = useReducer(reducer, getInitialState());

  const dispatch = (type, payload) => {
    _dispatch({type, payload})
  }

  const reload = () => {
    document.location.reload()
  }

  const renderLoadError = () => (
    <div className="container-fluid">
      <h4>Unable to load website information</h4>
    </div>
  )

  const renderIsIE = () => (
    <div className="container-fluid">
      <IENotSupported />
    </div>
  )

  if (props.systemError) {
    return renderLoadError();
  } else if (isIE()) {
    return renderIsIE();
  } else if (!state.isLoggedIn) {
    return (
      <div className="container-fluid">
        <HeaderNotLoggedIn {...props} {...state} dispatch={dispatch}/>
      </div>
    )
  } else {
    return (
      <div className="container-fluid">
        <AppContext.Provider value={{...props, ...state, dispatch} }>
          <HeaderLoggedIn />
          {
            (state.timeout) ?
              <Card>
                <CardHeader>
                  <h4 className="text-center">Your session has timed out. Please <span className="login-again"
                                                                                       onClick={reload}>log in again</span>
                  </h4>
                </CardHeader>
              </Card> :
              <Main />
          }
          <Footer />
        </AppContext.Provider>
      </div>
    )
  }
  // return render(props, state, props.information, dispatch);
}

export default App;
