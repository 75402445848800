import React from 'react';
import {Col, Row} from 'reactstrap';
import Google from './Google'

const HeaderNotLoggedIn = props => {

  const getMessage = () => {
    if (props.inProcess) {
      return <h1 className="header-title">Logging in...</h1>
    } else if (props.invalidCredentials) {
      return <h1 className="header-title">Invalid login credentials or user not authorized</h1>
    }
  }

  return (
    <Row className="header">
      <Col md={3}>
        <h1 className="header-title">Website Maintenance</h1>
      </Col>
      <Col md={6} className="text-center">
        {getMessage()}
      </Col>
      <Col md={3}>
        <Google {...props} />
      </Col>
    </Row>
  )

}

export default HeaderNotLoggedIn
