import getNewId from "../../utilities/getNewId";
const ID_SIZE = 20;

export default (mode, order) => {
  switch (mode) {
    case 'information':
      return {
        id: getNewId(ID_SIZE),
        order: order,
        text: '',
        title: ''
      }

    case 'admin':
    case 'counselors':
    case 'support':
      return {
        id: getNewId(ID_SIZE),
        extension: '',
        name: '',
        title: '',
        order: order
      }

    case 'links':
      return {
        id: getNewId(ID_SIZE),
        text: '',
        url: '',
        order: order
      }

    default:
      return {}
  }
}

