import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Card, CardHeader, CardBody } from 'reactstrap';
import AppContext from "../AppContext";
import 'handsontable/dist/handsontable.full.css';
import ItemList from "../Item/ItemList";
import UserPageControls from "./UserPageControls";
import UserPageItem from "./UserPageItem";
import newUserPage from "./newUserPage";
import format from 'date-fns/format';

const R = require('ramda');

const UserPages = props => {

  const appContext = useContext(AppContext);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const fieldsToFilter = ['text'];

  const [dirty, setDirty] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [noChangeAllowed, setNoChangeAllowed] = useState(false);

  useEffect(() => {
    setPageList(appContext.pageList ? appContext.pageList : []);
  }, [appContext.pageList])

  const onSelect = id => {
    if (!dirty) {
      let _selectedPage = R.find(it => it.id === id, pageList);
      setSelectedPage(_selectedPage);
      setSelectedPageId(_selectedPage.id);
    } else {
      setNoChangeAllowed(true);
      setTimeout(() => {
        setNoChangeAllowed(false);
      }, 3000);
    }
  }

  const onChange = evt => {
    let sp = R.clone(selectedPage);
    sp.text = evt.currentTarget.value;
    setIsValid(sp.text.length > 0);
    setSelectedPage(sp);
    setDirty(true);
  }

  const onAdd = () => {
    let sp = newUserPage();
    setSelectedPage(sp);
    setSelectedPageId(sp.id);
    setDirty(true);
    setIsValid(false);
  }

  const onCancel = () => {
    let _selectedPage = R.find(it => it.id === selectedPageId, pageList);
    setSelectedPage(_selectedPage);
    setDirty(false);
  }

  const onDelete = () => {
    setConfirmOpen(true);
  }

  const deleteItem = () => {
    appContext.db.collection(appContext.school)
      .doc(selectedPage.id).delete()
      .then(() => {
        setDirty(false);
        setConfirmOpen(false);
        setSelectedPageId(null);
        setSelectedPage(null);
      })
      .catch(err => {
        alert('Error deleting data');
        console.log(err);
      })
  }

  const onSave = () => {
    selectedPage.lastUpdated = (new Date()).getTime();
    appContext.db.collection(appContext.school)
      .doc(selectedPage.id).set(selectedPage)
      .then(() => {
        setDirty(false);
        setIsValid(true);
      })
      .catch(err => {
        alert("Error saving data");
      });
  }

  const toggleConfirm = () => {
    setConfirmOpen(!setConfirmOpen);
  }

  const listRender = it => (
    <div>
      <h5>{it.text}</h5>
      <h6>Last modified: {format(new Date(it.lastUpdated), 'MMM d, yyyy')} {it.static ? 'Non-removable' : ''}</h6>
    </div>
  )

  return (
    <>
      <Row>
        <Col md={4}>
          <ItemList title="User pages"
                    list={pageList}
                    itemKey={selectedPageId}
                    onSelect={onSelect}
                    fieldsToFilter={fieldsToFilter}
                    render={listRender} />
        </Col>
        <Col md={8}>
          <Card>
            <CardHeader>
              <UserPageControls onAdd={onAdd}
                                onCancel={onCancel}
                                onDelete={onDelete}
                                onSave={onSave}
                                dirty={dirty}
                                isValid={isValid}
                                selectedPage={selectedPage}
              />
            </CardHeader>
            <CardBody>
              <UserPageItem item={selectedPage}
                            isValid={isValid}
                            onChange={onChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={confirmOpen} toggle={toggleConfirm}>
        <ModalHeader toggle={toggleConfirm}>Confirm delete webpage</ModalHeader>
        <ModalBody><h5>Are you sure you want to delete this complete web page?</h5></ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={deleteItem}>Yes</Button>{' '}
          <Button color="secondary" onClick={toggleConfirm}>No</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={noChangeAllowed}>
        <ModalBody>Please save or cancel your changes before selecting a new item</ModalBody>
      </Modal>
    </>
  )
}

export default UserPages;
