import React from 'react';
import BulletinItem from "./BulletinItem";
import AnnouncementItem from "./AnnouncementItem";
import format from "date-fns/format";

// Display the rendered item as an announcement and a bulletin item
const AnnBullItemDisplay = props => {

  props.item.dateOnStr = format(props.item.dateOn, 'MMM d');
  props.item.dateOffStr = format(props.item.dateOff, 'MMM d');

  return (
    <div>
      {props.item.onBulletin ? BulletinItem (props.item) : null}
      {props.item.onAnnouncement ? AnnouncementItem (props.item) : null}
    </div>
  )

}

export default AnnBullItemDisplay;
