import React, { useState, useEffect, useRef } from "react";
import { nanoid } from "nanoid";

/**
 * clientId: Obtained from APIs & Services | Credentials | OAuth2 Client IDs page in the GCloud console
 * divRef: HTML <div> element containing the sign-in button
 * options: see https://developers.google.com/identity/gsi/web/reference/js-reference)
 */
export default function GoogleSignin(props) {
  const {
    clientId,
    options,
    signinCallback,
    errorCallback
  } = props;
  const [google, setGoogle] = useState();
  const [googleIsLoading, setGoogleIsLoading] = useState(true);

  const divRef = useRef(null);

  const useInterval = (callback, delay) => {
    const savedCallback = React.useRef();

    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, []);
  };

  useInterval(
    () => {
      if (typeof window !== "undefined" && window.google) {
        setGoogle(window.google);
        setGoogleIsLoading(false);
      }
    },
    googleIsLoading ? 100 : null
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  useEffect(() => {
    const _options = {
      type: 'standard',
      theme: 'filled_blue',
      size: 'large',
      text: 'signin_with',
      shape: 'rectangular',
      logo_alignment: 'left'
    }

    const $options = {..._options, ...options};
    if (google && divRef.current && $options) {
      try {
        google.accounts.id.initialize({
          client_id: clientId,
          callback: async (res) => {
            signinCallback && signinCallback(res.credential);
          },
          nonce: nanoid(),
        });
        google.accounts.id.renderButton(divRef.current, $options)
      } catch (error) {
        errorCallback && errorCallback(error);
      }
    }
  }, [clientId, errorCallback, google, options, signinCallback])

  return (
    <div ref={divRef} />
  )
}
