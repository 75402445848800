import React from 'react';
import { Card, CardHeader, CardBody, CardText } from 'reactstrap';

// Bulletin item
const BulletinItem = it => {
  return (
    <Card className="bulletin-announcement-separator">
      <CardHeader><h5>Bulletin</h5></CardHeader>
      <CardBody>
        <Card>
        <CardText className="bulletin-item">
          <span className="bulletin-header">{it.tease.toUpperCase()}</span>{':  '}
          <span dangerouslySetInnerHTML={{__html: it.itemText}}></span>{'  '}
          <span>Contact: <a href={'mailto:' + it.contactEmail}>{it.contact}</a>
            {
              !it.alwaysOn ?
                <>
                  {'  '}
                  {it.dateOnStr} - {it.dateOffStr}
                </> : null
            }
          </span>
        </CardText>
        </Card>
      </CardBody>
    </Card>
  )
}

export default BulletinItem;
