import getNewId from "../../utilities/getNewId";
const ID_SIZE = 20;

export default () => {
  return {
    id: getNewId(ID_SIZE),
    department: '',
    email: '',
    extension: '',
    firstName: '',
    lastName: '',
    per0: '',
    per1: '',
    per2: '',
    per3: '',
    per4: '',
    per5: '',
    per6: '',
    per7: '',
    per8: '',
    per9: '',
    pictureUrl: '',
    room: '',
    webpage: ''
  }
}

