import React from 'react';
import {Col, Row} from 'react-bootstrap';

const IENotSupported = () => {

  return (
    <Row className="header">
      <Col md={12} className="text-center">
        <h1 className="header-title text-center">Website Management</h1>
        <h5 className="text-center">Internet Explorer is not supported</h5>
        <h5 className="text-center">Please use Chrome or Firefox for best results</h5>
      </Col>
    </Row>
  )
}

export default IENotSupported
