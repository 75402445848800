const R = require('ramda');
const getInitialState = require('./getInitialState')

/**
 * Reducer for user state
 * @param state
 * @param action
 * @returns new state
 */
module.exports = (state, pkg = null) => {

  console.log(pkg);

  if (pkg) {
    switch (pkg.type) {

      /********** AUTH actions ************/
      case 'AUTH:INVALID_CREDENTIALS':
        state = R.mergeRight(state, {
          inProcess: false,
          invalidCredentials: true,
          isLoggedIn: false,
          domainError: false,
          systemError: false,
          timeout: false,
          id_token: '',
          user: null,
        })
        break;

      case 'AUTH:DOMAIN':
        state = R.mergeRight(state, {
          inProcess: false,
          invalidCredentials: false,
          isLoggedIn: false,
          domainError: true,
          timeout: false,
          systemError: false,
          id_token: '',
          user: null,
        })
        break;

      case 'AUTH:TIMEOUT':
        state = R.mergeRight(state, {
          inProcess: false,
          invalidCredentials: false,
          isLoggedIn: false,
          domainError: false,
          systemError: false,
          timeout: true,
          id_token: '',
          user: null,
        })
        break;

      case 'AUTH:SYSTEM_ERROR':
        state = R.mergeRight(state, {
          inProcess: false,
          invalidCredentials: false,
          isLoggedIn: false,
          domainError: false,
          systemError: true,
          timeout: false,
          id_token: '',
          user: null,
        })
        break;

      case 'AUTH:AUTHORIZATION':
        state = R.mergeRight(state, {
          inProcess: false,
          invalidCredentials: true,
          isLoggedIn: false,
          domainError: false,
          systemError: false,
          timeout: false,
          id_token: '',
          user: null,
        })
        break;

      case 'AUTH:SUCCESS':
        state = R.mergeRight(state, {
          inProcess: false,
          invalidCredentials: false,
          isLoggedIn: true,
          domainError: false,
          systemError: false,
          timeout: false,
          db: pkg.payload.db,
          firebase: pkg.payload.firebase,
          storage: pkg.payload.storage,
          id_token: pkg.payload.id_token,
          user: pkg.payload
        })
        break;

      case 'AUTH:IN_PROCESS':
        state = R.mergeRight(state, {
          inProcess: true
        });
        break;

      case 'AUTH:LOGOUT':
        state = getInitialState();
        break;

      // /********** DATA actions ************/

      case 'DATA:SYSTEM':
        state = R.mergeRight(state, pkg.payload);
        break;

      case 'DATA:PAGE_LIST':
        state = R.mergeRight(state, {pageList: pkg.payload});
        break;

      case 'DATA:ERROR_RETRIEVING_DATA':
        break;

      // case 'DATA:REASONS':
      //   state = R.mergeRight(state, {reasons: pkg.payload});
      //   break;
      //
      // case 'DATA:STUDENT_LIST_OBJ':
      //   state = R.mergeRight(state, {studentObjs: pkg.payload});
      //   break;
      //
      // case 'DATA:SELECTED_STUID':
      //   state = R.mergeRight(state, {selectedStuID: pkg.payload});
      //   break;
      //
      // case 'DATA:SELECT_STUDENT':
      //   state = R.mergeRight(state, {
      //     selectedStuID: pkg.payload,
      //     activePage: 'lopStatus'
      //   })
      //   break;
      //
      // /********** NAVBAR actions ************/
      case 'NAV:GOTO_PAGE':
        state = R.mergeRight(state, {activePage: pkg.payload});
        break;


      // case AC.PAGES_PROFILE_GRID_CHANGE_FILTER:
      //   state = state.set('student', null)
      //   break;
      //
      // case AC.DATA_SET_STUDENT_LIST:
      //   state = state.set('studentList', pkg.payload.studentList)
      //   break
      //
      // case AC.DATA_SET_STUDENT:
      //   state = state.set('student', pkg.payload.student)
      //   break
      //
      // case AC.DATA_SET_STUDENT_NOTES:
      //   state = state.set('studentNotes', pkg.payload.studentNotes)
      //   break
      //
      // case AC.DATA_SET_STUDENT_SCHEDULE:
      //   state = state.set('studentSchedule', pkg.payload.studentSchedule)
      //   break;
      //
      // case AC.DATA_SET_AT_RISK_CONFIGURATION:
      //   state = state.set('atRiskConfiguration', pkg.payload.atRiskConfiguration)
      //   break
      //
      // case AC.DATA_SET_COURSE_LIST:
      //   let reduceWithIdx = R.addIndex(R.reduce)
      //   let courseIndex = reduceWithIdx((acc, c, idx) => R.assoc(c.course_number, idx, acc), {}, pkg.payload.courseList)
      //   state = state.set('courseList', pkg.payload.courseList)
      //   state = state.set('courseIndex', courseIndex)
      //   break
      //
      // case AC.DATA_SET_SCHOOL_LIST:
      //   state = state.set('schoolList', pkg.payload.schoolList)
      //   break
      //
      // case AC.STUDENT_GET_TAGS:
      //   state = state.set('studentTags', pkg.payload.tags)
      //   break
      //
      // case AC.DATA_SET_COUNTS:
      //   var field = (pkg.payload.table === 'ps_grades') ? 'transcriptCount' : 'scheduleCount'
      //   state = state.set(field, pkg.payload.countList)
      //   break
      //
      default:
        break

    }
  }

  return state;
}
