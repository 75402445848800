const processFirestoreCollectionData = (list, field) => {
  list = list.docs.map(item => {
    let it = item.data();
    it[field] = item.id;
    return it;
  });
  return list
}

module.exports = processFirestoreCollectionData;
