import React, { useState, useEffect } from 'react';
import { Col, Modal, ModalBody } from 'reactstrap';
import PageList from "./PageList";
import PageItemEdit from "./PageItemEdit";

const R = require('ramda');

const PageItems = props => {

  const [selectedId, setSelectedId] = useState(null);
  const [dirty, setDirty] = useState(false);
  const [noChangeAllowed, setNoChangeAllowed] = useState(false);

  useEffect(() => {
    setSelectedId(null);
  }, [props.mode]);

  const onSelectedItemClick = evt => {
    if (!dirty) {
      let id = evt.currentTarget.getAttribute('data-id');
      setSelectedId(id);
      setDirty(false);
    } else {
      setNoChangeAllowed(true);
      setTimeout(() => {
        setNoChangeAllowed(false);
      }, 3000);
    }
  }

  const clearSelectedId = () => {
    setSelectedId(null);
    setDirty(false);
  }

  let selectedItem = selectedId ? (R.find(item => item.id === selectedId, props.items)) : null;
  let maxOrder = props.items.reduce((max, item) => Math.max(max, item.order), 0);

  return (
    <>
      <Col md={6}>
        <PageList mode={props.mode}
                  items={props.items}
                  metadata={props.metadata}
                  onSelectedItemClick={onSelectedItemClick}
                  clearSelectedId={clearSelectedId}
                  selectedId={selectedId}
                  dirty={dirty}
        />
      </Col>
      <Col md={6}>
        <PageItemEdit mode={props.mode}
                      maxOrder={maxOrder}
                      itemCount={props.items ? props.items.length : 0}
                      item={selectedItem}
                      metadata={props.metadata}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      clearSelectedId={clearSelectedId}
                      setDirty={setDirty}
                      dirty={dirty}
        />
      </Col>
      <Modal isOpen={noChangeAllowed}>
        <ModalBody>Please save or cancel your changes before selecting a new item</ModalBody>
      </Modal>
    </>
  )
}

export default PageItems;
