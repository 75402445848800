import React from 'react';
import {Col, Row} from 'reactstrap';

const packageJSON = require('../../package.json')

export default () => {
  return (
    <Row className="footer">
      <Col md={12}>
        <h6>PHHS Website Maintenance<br/>
          Copyright {packageJSON.copyright}<br/>
          <a href="https://docs.google.com/document/d/1i8Ut5sncfax8P5mu-oD9MfniJuwuAzKELSPp2UhAqUw/edit?usp=sharing"
             target="_blank" rel="noopener noreferrer">Instructions</a><br/>
          Version {packageJSON.version}
        </h6>
      </Col>
    </Row>
  )
}
