import React  from 'react';
import { Col, Button, Form, FormGroup, FormFeedback, Input, InputGroup, InputGroupAddon, Label,
  Card, CardHeader, CardBody } from 'reactstrap';
import constants from '../../constants';

const UserPageItem = props => {

  const onCopy = () => {
    let copyText = document.querySelector("#pageURL");
    copyText.select();
    document.execCommand("copy");
  }

  return (
    props.item ?
      <div>
        <Card>
          <CardHeader>User-defined pages</CardHeader>
          <CardBody>
            <Form>
              <FormGroup row>
                <Label for="pageName" sm={4}>Page name</Label>
                <Col sm={8}>
                  <Input invalid={!props.isValid} type="text" name="pageName" id="pageName" onChange={props.onChange} value={props.item.text} />
                  <FormFeedback>The page name is required</FormFeedback>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="" sm={4}>Page URL</Label>
                <Col sm={8}>
                  <InputGroup>
                    <Input type="text" id="pageURL" readOnly className="unshaded-input" value={constants.URL+'/'+props.item.id} />
                    <InputGroupAddon addonType="append">
                      <Button onClick={onCopy}>Copy</Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div> : <h5>Select a page</h5>
  )
}

export default UserPageItem;
