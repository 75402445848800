import React, {useContext, useEffect, useState} from 'react';
import {Row, Col} from 'reactstrap'
import ItemList from "../Item/ItemList";
import UsersItem from "./UsersItem";
import { useCollection } from "react-firebase-hooks/firestore";
import processFirestoreData from '../../utilities/processFirestoreCollectionData'
import AppContext from "../AppContext";

const R = require('ramda')

const Users = props => {

  let [itemKey, setItemKey] = useState(null);
  let appContext = useContext(AppContext);
  const fieldsToFilter = ['email'];

  let [_list/*, loading, error*/] = useCollection(appContext.db.collection(appContext.school).doc('users').collection('userList'));

  let list = _list ? processFirestoreData(_list, 'id') : [];
  list = list.sort((a, b) => a.email < b.email ? -1 : 1);
  let item = itemKey && list ? R.find(it => it.id === itemKey, list) : null;

  const onSelect = id => {
    setItemKey(id);
  }

  const clearSelectedItem = () => {
    setItemKey(null);
  }

  const listRender = it => (
    <h5>{it.email}</h5>
  )

  return (
    <Row>
      <Col md={4}>
        <ItemList title={'Users List'}
                  list={list}
                  itemKey={itemKey}
                  onSelect={onSelect}
                  fieldsToFilter={fieldsToFilter}
                  render={listRender} />
      </Col>
      <Col md={8}>
        <UsersItem item={item} onSelect={onSelect} clearSelectedItem={clearSelectedItem}/>
      </Col>
    </Row>
  )
}

export default Users;
